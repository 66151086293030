<template>
    <div>
        <div class="split left"></div>
        <div></div>
        <div class="split right">
            <form @submit.prevent="handleSubmit(!v$.$invalid)">
                <div class="centered">
                    <img class="logo" src="layout/images/logo.png" alt="Toorbee logo" />
                    <h4 id="title">Register your company</h4>
                    <div class="grid flex-column">


                        <div class="col">
                            <div class="field p-fluid">
                                <label for="username">Username</label>
                                <InputText id="username" type="text" v-model="v$.username.$model" :class="{ 'p-invalid': v$.username.$invalid && submitted }" />
                                <small v-if="(v$.username.$invalid && submitted) || v$.username.$pending.$response" class="p-error">{{ v$.username.required.$message.replace('Value', 'Username') }}</small>
                            </div>

                            <div class="field p-fluid">
                                <label for="password">Password</label>
                                <InputText id="password" type="password" v-model="v$.password.$model" :class="{ 'p-invalid': v$.password.$invalid && submitted }" />
                                <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ v$.password.required.$message.replace('Value', 'Password') }}</small>
                            </div>
                            <div class="field p-fluid">
                                <label for="email">Email</label>
                                <InputText id="email" type="email" :class="{ 'p-invalid': v$.email.$invalid && submitted }" v-model="v$.email.$model" aria-describedby="email-error" />
                                <span v-if="v$.email.$error && submitted">
                                    <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                                        <small class="error">{{ error.$message }}</small>
                                    </span>
                                </span>
                                <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message.replace('Value', 'Email') }}</small>
                            </div>
                            <div class="field p-fluid">
                                <label for="companyName">Company name</label>
                                <InputText id="companyName" type="text" v-model="v$.companyName.$model" :class="{ 'p-invalid': v$.companyName.$invalid && submitted }" />
                                <small v-if="(v$.companyName.$invalid && submitted) || v$.companyName.$pending.$response" class="p-error">{{ v$.companyName.required.$message.replace('Value', 'Company name') }}</small>
                            </div>
                            <div class="field p-fluid">
                                <label for="profession">Profession</label>
                                <InputText id="profession" type="text" v-model="v$.profession.$model" :class="{ 'p-invalid': v$.profession.$invalid && submitted }" />
                                <small v-if="(v$.profession.$invalid && submitted) || v$.profession.$pending.$response" class="p-error">{{ v$.profession.required.$message.replace('Value', 'Profession') }}</small>
                            </div>
                            <div class="field p-fluid">
                                <label for="companyType">Company type</label>
                                <Dropdown :options="types" placeholder="Select company type" v-model="v$.companyType.$model" :class="{ 'p-invalid': v$.companyType.$invalid && submitted }" />
                                <small v-if="(v$.companyType.$invalid && submitted) || v$.companyType.$pending.$response" class="p-error">{{ v$.companyType.required.$message.replace('Value', 'Company type') }}</small>
                            </div>
                            <div class="field p-fluid">
                                <label for="vat">Company VAT number</label>
                                <InputText id="vat" type="text" v-model="v$.vat.$model" :class="{ 'p-invalid': v$.vat.$invalid && submitted }" />
                                <small v-if="(v$.vat.$invalid && submitted) || v$.vat.$pending.$response" class="p-error">{{ v$.vat.required.$message.replace('Value', 'VAT') }}</small>
                            </div>
                        </div>
                        <div id="buttonBox">
                            <Button id="button" label="Register now" class="p-button-rounded" type="submit"/>
                        </div>

                    </div>
                        <div class="text">
                            <p>
                                Do you have an account?
                                <a style="text-decoration: underline" href="javascript:void(0)" @click="login">Login now</a>
                            </p>
                        </div>
                </div>
            </form>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Footer from '../AppFooter.vue';
import { email, required } from '@/utilities/i18n-validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'Register',
    components: {
        Footer,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            username: '',
            password: '',
            email: '',
            companyName: '',
            profession: '',
            companyType: '',
            vat: '',
            types: ['test1', 'test2'],
            submitted: false,
        };
    },
    validations() {
        return {
            username: {
                required,
            },
            password: {
                required,
            },
            email: {
                required,
                email,
            },
            companyName: {
                required,
            },
            profession: {
                required,
            },
            companyType: {
                required,
            },
            vat: {
                required,
            },
        };
    },
    methods: {
        handleSubmit(isFormValid) {
            this.submitted = true;
            // this.$router.push('Page')
            if (!isFormValid) {
                return;
            }

            console.log('OK');
        },
        async login() {
            await this.$store.dispatch("login");
          }
    },
};
</script>

<style lang="scss" scoped>
.split {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.left {
    left: 0;
    width: 70%;
    background-color: #111;
}

.right {
    right: 0;
    width: 30%;
}

#title {
    text-align: center;
}

#buttonBox {
    text-align: center;
    margin-top: 1%;
}

.text {
    margin-top: 2%;
    text-align: center;
}

#button {
    background-color: #f8981d;
    padding-right: 13%;
    padding-left: 13%;
}

.centered {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 10%;
    // transform: translate(-50%, -50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    // text-align: center;
}

/* Style the image inside the centered container, if needed */
.centered img {
    // width: 150px;
    border-radius: 50%;
    top: 0%;
}

.sizes {
    .inputtext {
        display: block;
        margin-bottom: 0.5rem;
        margin: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.field {
    display: block;
// margin:0 !important;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>
