import { app } from "../main";

export default {
  checkPermission(state) {
    // return permissions => permissions.every(el => state.userInfo.permissions.some(el1 => el1.name === el));
    return (permissions) =>
      permissions.every((el) =>
        state.userInfo.claims.resource_access[
          "spring-cloud-gateway-client"
        ].roles.some((el1) => el1 === el)
      );
  },
  notifications(state) {
    return state.notifications;
  },
  getUserRole(state) {
    return state.userInfo.roles.filter(
      (role) =>
        role != "default-roles-microservices-realm" &&
        role != "offline_access" &&
        role != "moderator" &&
        role != "uma_authorization"
    );
  },
  getUserRoleTranslated(state) {
    let role = state.userInfo.roles.filter(
      (role) =>
        role != "default-roles-microservices-realm" &&
        role != "offline_access" &&
        role != "moderator" &&
        role != "uma_authorization"
    );
    role = app.config.globalProperties.$t(`userRoles.${role[0]}`);
    return role;
  },
  version(state) {
    return state.version;
  },
  checkRole(state) {
    return (roles) =>
      roles.some((el) => state.userInfo.roles.some((el1) => el1 === el));
  },
  roles(state) {
    return state.roles;
  },
  getRole(state) {
    return (id) =>
      state.roles.filter((el) => {
        return el.id == id;
      })[0];
  },
  users(state) {
    return state.users;
  },
  getUser(state) {
    return (id) =>
      state.users.filter((el) => {
        return el.id == id;
      })[0];
  },
  permissions(state) {
    return state.permissions;
  },
  certCategories(state) {
    return state.certCategories;
  },
  certCategoriesNotDeleted(state) {
    return state.certCategories.filter(el => !el.deleted);
  },
  certCategoryById(state) {
    return (certId) => state.certCategories.find(el => el.id == certId);
  },
  userInfo(state) {
    return state.userInfo;
  },
  userDataId(state) {
    return state.userDataId;
  },
  clientInfo(state) {
    return state.clientInfo;
  },
  clientInfoId(state) {
    return state.clientInfoId[0];
  },
  applications(state) {
    return state.applications;
  },
  contracts(state) {
    return state.contracts;
  },
  cases(state) {
    return state.cases;
  },
  casesRating(state) {
    return state.cases.filter((el) => el.rating != null);
  },
  caseById(state) {
    return (id) =>
      state.cases.filter((el) => {
        return el.id == id;
      })[0];
  },
  getScheme(state) {
    return (id) =>
      state.certCategories.filter((el) => {
        return el.id == id;
      })[0];
  },
  auditPlans(state) {
    return state.auditPlans;
  },
  auditCaseById(state) {
    return (id) =>
      state.cases.filter((el) => {
        return el.id == id;
      })[0];
  },
  getCalendarCases(state) {
    state.cases.forEach((el) => {
      el.title = `${app.config.globalProperties.$t(
        "calendar.auditCases",
        1
      )} #${el.id}`;
      el.start = app.config.globalProperties
        .$dayjs(el.auditDate, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      el.end = app.config.globalProperties
        .$dayjs(el.auditDateEnd, "DD/MM/YYYY")
        .add(1, "day")
        .format("YYYY-MM-DD");
      switch (el.status) {
        case state.CaseStatuses.DATE_OVERDUE.name:
          el.color = state.CaseStatuses.DATE_OVERDUE.color;
          break;
        case state.CaseStatuses.CREATE_PLAN.name:
          el.color = state.CaseStatuses.CREATE_PLAN.color;
          break;
        case state.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name:
          el.color = state.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.color;
          break;
        case state.CaseStatuses.EVENT_CREATED.name:
          el.color = state.CaseStatuses.EVENT_CREATED.color;
          break;
        case state.CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.EVENT_REJECTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.EVENT_REJECTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.SCHEDULE_CREATED.name:
          el.color = state.CaseStatuses.SCHEDULE_CREATED.color;
          break;
        case state.CaseStatuses.SCHEDULE_ACCEPTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.SCHEDULE_ACCEPTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.TO_BE_AUDITED.name:
          el.color = state.CaseStatuses.TO_BE_AUDITED.color;
          break;
        case state.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.name:
          el.color = state.CaseStatuses.TO_BE_REVIEWED_BY_REVIEWER.color;
          break;
        case state.CaseStatuses.AUDIT_REJECTED_BY_CLIENT.name:
          el.color = state.CaseStatuses.AUDIT_REJECTED_BY_CLIENT.color;
          break;
        case state.CaseStatuses.TO_BE_CERTIFIED.name:
          el.color = state.CaseStatuses.TO_BE_CERTIFIED.color;
          break;
        case state.CaseStatuses.TO_BE_AMENDED.name:
          el.color = state.CaseStatuses.TO_BE_AMENDED.color;
          break;
        case state.CaseStatuses.REJECTED_BY_CERTIFIER.name:
          el.color = state.CaseStatuses.REJECTED_BY_CERTIFIER.color;
          break;
        case state.CaseStatuses.CERTIFIED.name:
          el.color = state.CaseStatuses.CERTIFIED.color;
          break;
        case state.CaseStatuses.AUDIT_FAILED.name:
          el.color = state.CaseStatuses.AUDIT_FAILED.color;
          break;
        default:
          break;
      }
    });
    return state.cases;
  },

  getCalendarPlans(state) {
    state.auditPlans.forEach((el) => {
      if (el.status == "accepted") {
        el.color = "#34c73b";
      } else if (el.status == "rejected") {
        el.color = "#e91e63";
      } else {
        el.color = "#ff9800";
      }

      el.title = `${app.config.globalProperties.$t(
        "calendar.auditPlans",
        1
      )} #${el.id}`;
      el.start = el.auditDate;
      el.end = app.config.globalProperties
        .$dayjs(el.auditDateEnd)
        .add(1, "day")
        .format("YYYY-MM-DD");
    });

    return state.auditPlans;
  },

  getCalendarTasks(state) {
    let auditTasks = [];
    state.auditPlans.forEach((el) => {
      auditTasks.push(...el.auditTasks);
    });

    auditTasks.forEach((el) => {
      el.date = el.auditDate;
    });

    return auditTasks;
  },
  getApplicationPdf(state) {
    return state.applicationPdf;
  },
  files(state) {
    return state.files;
  },
  certifications(state) {
    return state.certifications;
  },
  availableAuditors(state) {
    return state.availableAuditors;
  },
  templates(state) {
    return state.templates;
  },
  dialogCases(state) {
    return state.dialogCases;
  },
  checkAuditorsAvailability(state) {
    return (date) => state.auditorsAvailability[date] > 0;
  },
  userProfileById(state) {
    return state.userProfileById;
  },
  countries(state) {
    return state.countries;
  },
  companyTypes(state) {
    return state.companyTypes;
  },
  doyOptions(state) {
    return state.doyOptions;
  },
  auditCaseFiles(state) {
    return state.auditCaseFiles;
  },
  invoiceView(state) {
    return state.invoiceView;
  },
  renewals(state) {
    return state.renewals;
  },
  translatedStatus() {
    return (status) => app.config.globalProperties.$t(`statuses.${status}`);
  },
  translateFileTypes() {
    return (type) => app.config.globalProperties.$t(`fileTypes.${type}`);
  },
  invoiceCases(state) {
    return state.cases.filter(
      (el) => el.invoiceId != null && el.invoiceStatus != null
    );
  },
  actions(state) {
    return state.actions;
  },
  fileToView(state) {
    return state.fileToView;
  },
  renewalDropdown(state) {
    return state.renewalDropdown;
  },
  getConnected(state) {
    return state.setConnected;
  },
  usersRegisteredDashboard(state) {
    return state.usersRegisteredDashboard;
  },
  applicationsDashboard(state) {
    return state.applicationsDashboard;
  },
  certificationsDashboard(state) {
    return state.certificationsDashboard;
  },
  casesDashboard(state) {
    return state.casesDashboard;
  },
  auditPlansDashboard(state) {
    return state.auditPlansDashboard;
  },
  companiesDashboard(state) {
    return state.companiesDashboard;
  },
  citiesDashboard(state) {
    return state.citiesDashboard;
  },
  visitsDashboard(state) {
    return state.visitsDashboard;
  },
  registeredGraphDashboard(state) {
    let labels = [];
    let data = [];

    for (const date in state.registeredGraphDashboard) {
      labels.push(date);
      data.push(state.registeredGraphDashboard[date]);
    }

    return { labels, data };
  },
  auditPlansGraphDashboard(state) {
    let labels = [];
    let data = [];

    for (const date in state.auditPlansGraphDashboard) {
      labels.push(date);
      data.push(state.auditPlansGraphDashboard[date]);
    }

    return { labels, data };
  },
  checkDeletedAccount(state) {
    return state.deleteAccount;
  },
  notificationsObject(state) {
    return state.notificationsObject;
  },
  screenWidth(state) {
    return state.screenWidth;
  },
  actionsObject(state) {
    return state.actionsObject;
  },
  cities(state) {
    return state.cities;
  },
  renewalTime(state) {
    return state.renewalTime;
  },
  ratingTime(state) {
    return state.ratingTime;
  },
  actionsGrouped(state) {
    return state.actionsGrouped;
  },
  radius(state) {
    return state.radius;
  },
  deletePeriod(state) {
    return state.deletePeriod;
  },
  isMobile() {
    let check = false;
    (function (a) {
      //eslint-disable-next-line
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        //eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  notificationForActions(state) {
    let notifications = [];
    state.notificationsObject.forEach(el => {
      notifications.push(el.notification);
    })
    return notifications;
  }
};
