export default {
  token: "",
  version: process.env.PACKAGE_VERSION,
  userInfo: {},
  clientInfoId: {},
  notifications: [],
  clientInfo: {},
  roles: [],
  users: [],
  certCategories: [],
  permissions: [],
  applications: [],
  contracts: [],
  cases: [],
  auditPlans: [],
  pagesPermissions: {
    roles: ["admin"],
    certifications: [
      "client",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
      "admin",
    ],
    users: ["admin"],
    files: [
      "client",
      "admin",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
    ],
    schemes: ["admin", "certification-manager"],
    calendar: [
      "admin",
      "client",
      "audit-planner",
      "auditor",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
      "head-audit-planner",
      "head-manager-for-audit-assignment",
      "manager-for-audit-assignment",
    ],
    // profile: ["admin", "client", "application-reviewer", "sales", "certification-manager", "quality-assurance-manager", "product-manager", "head-application-reviewer"],
    applications: [
      "client",
      "admin",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
      "application-reviewer",
      "product-manager",
      "head-application-reviewer",
    ],
    createApplication: ["client"],
    applicationInformation: ["client"],
    applicationDetails: ["client"],
    applicationDownloadAndSign: ["client"],
    applicationUploadSigned: ["client"],
    contracts: [
      "client",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
      "admin",
    ],
    createContracts: ["sales"],
    clients: [
      "admin",
      "certification-manager",
      "quality-assurance-manager",
      "application-reviewer",
      "sales",
      "head-application-reviewer",
      "accounting",
      "manager-for-certification-decision",
      "head-audit-planner",
      "audit-planner",
      "product-manager",
      "auditor",
    ],
    cases: [
      "admin",
      "client",
      "certification-manager",
      "quality-assurance-manager",
      "product-manager",
      "head-audit-planner",
      "head-manager-for-audit-assignment",
    ],
    auditCases: [
      "technical-reviewer",
      "manager-for-certification-decision",
      "head-technical-reviewer",
    ],
    invoices: ["accounting", "client"],
    settings: ["admin"],
    audits: ["auditor"],
    ratings: ["quality-assurance-manager"],
    complaints: ["quality-assurance-manager"],
    renewals: [
      "sales",
      "admin",
      "product-manager",
      "quality-assurance-manager",
    ],
  },
  applicationPdf: {},
  files: [],
  certifications: [],
  availableAuditors: [],
  templates: [],
  dialogCases: [],
  userDataId: {},
  CaseStatuses: {
    DATE_OVERDUE: {
      name: "case date overdue",
      color: "#d10412",
    },
    CREATE_PLAN: {
      name: "to create audit plan",
      color: "#3960d1",
    },
    TO_BE_ACCEPTED_BY_AUDIT_MANAGER: {
      name: "to be accepted by manager for audit assignment",
      color: "#170f00",
    },
    EVENT_CREATED: {
      name: "audit event created",
      color: "#ff9800",
    },
    EVENT_ACCEPTED_BY_CLIENT: {
      name: "event accepted by client",
      color: "#34c73b",
    },
    EVENT_REJECTED_BY_CLIENT: {
      name: "event rejected by client",
      color: "#e91e63",
    },
    SCHEDULE_CREATED: {
      name: "audit schedule created",
      color: "#00fc9c",
    },
    SCHEDULE_ACCEPTED_BY_CLIENT: {
      name: "schedule accepted by client",
      color: "#cc33ff",
    },
    SCHEDULE_REJECTED_BY_CLIENT: {
      name: "schedule rejected by client",
      color: "#fc0000",
    },
    TO_BE_AUDITED: {
      name: "to be audited",
      color: "#cc9c00",
    },
    TO_BE_ACCEPTED_BY_CLIENT: {
      name: "to be accepted by client",
      color: "#abd2f7",
    },
    TO_BE_REVIEWED_BY_REVIEWER: {
      name: "to be reviewed by technical reviewer",
      color: "#2b00cc",
    },
    AUDIT_REJECTED_BY_CLIENT: {
      name: "audit rejected by client",
      color: "#00272e",
    },
    TO_BE_CERTIFIED: {
      name: "to be certified",
      color: "#3489ff",
    },
    TO_BE_AMENDED: {
      name: "to be amended by auditor",
      color: "#01207a",
    },
    REJECTED_BY_CERTIFIER: {
      name: "rejected by certifier",
      color: "#ac2d00",
    },
    CERTIFIED: {
      name: "certified",
      color: "#d6d6d6",
    },
    AUDIT_FAILED: {
      name: "audit failed",
      color: "#1976D2"
    }
  },
  auditorsAvailability: {},
  userProfileById: {},
  countries: [],
  companyTypes: [],
  doyOptions: [],
  auditCaseFiles: [],
  invoiceView: {},
  renewals: [],
  actions: [],
  fileToView: "",
  renewalDropdown: {},
  setConnected: false,
  usersRegisteredDashboard: null,
  applicationsDashboard: null,
  certificationsDashboard: null,
  casesDashboard: null,
  auditPlansDashboard: null,
  companiesDashboard: null,
  citiesDashboard: null,
  visitsDashboard: {
    totalVisits: 0,
    dailyVisits: 0
  },
  registeredGraphDashboard: {},
  auditPlansGraphDashboard: {},
  deleteAccount: null,
  screenWidth: window.innerWidth,
  cities: [],
  notificationsObject: [],
  actionsObject: [],
  renewalTime: {},
  ratingTime: {},
  actionsGrouped: [],
  radius: 0,
  deletePeriod: {},
};
