const axios = require("axios");
// import Cookies from "js-cookie";
import { app } from "../main";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status != 404) {
      if (error.response.status == 400) {
        if (
          typeof error.response.data === "string" &&
          error.response.data.length > 0
        ) {
          app.config.globalProperties.$notification(
            app.config.globalProperties.$t("global.generalError"),
            // app.config.globalProperties.$t("global.errorMessage"),
            app.config.globalProperties
              .$t("global.errorMessageWithText")
              .replace("[message]", error.response.data),
            "error"
          );
        } else {
          app.config.globalProperties.$notification(
            app.config.globalProperties.$t("global.generalError"),
            app.config.globalProperties.$t("global.errorMessage"),
            "error"
          );
        }
      }

      if (error.response.status == 401) {
        app.config.globalProperties.$keycloak.login();
      }

      return Promise.reject(error);
    }else {
      return Promise.resolve(error.response);
    }
  }
);
// sessionStorage.setItem("test","test");

// let token = window.localStorage ? localStorage.getItem("SESSION") : Cookies.get("SESSION");
// const token = window.localStorage ? localStorage.getItem("SESSION") : sessionStorage.getItem("SESSION");
// if(window.localStorage) {
//   token = localStorage.getItem("SESSION");
// }else if(Cookies.get("SESSION")) {
//   token = Cookies.get("SESSION");
// }
// console.log(token);
instance.interceptors.request.use(function (config) {
  config.headers.Authorization =
    "Bearer " + app.config.globalProperties.$keycloak.token;

  return config;
});

export default {
  async login() {
    let keycloak = this.app.config.globalProperties.$keycloak;
    await keycloak
      .login({
        redirectUri: process.env.VUE_APP_FRONT_URL + "/dashboard",
      })
      .then((res) => {
        console.log(res);
        console.log(keycloak);
        // Cookies.set('SESSION',keycloak.token, {path:'http://localhost:8082'});
        // store.commit("login");
        // localStorage.setItem("vue-token", keycloak.token);
        keycloak
          .loadUserProfile()
          .then(function (profile) {
            alert(JSON.stringify(profile, null, "  "));
          })
          .catch(function () {
            alert("Failed to load user profile");
          });
      });
  },
  async updateToken() {
    let keycloak = this.app.config.globalProperties.$keycloak;

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            // if(window.localStorage) {
            //   localStorage.setItem("SESSION", keycloak.token);
            // }else {
            //   Cookies.set("SESSION", keycloak.token);
            // }
            console.log("Token refreshed" + refreshed);
          } else {
            // console.log(
            //   "Token not refreshed, valid for " +
            //     Math.round(
            //       keycloak.tokenParsed.exp +
            //         keycloak.timeSkew -
            //         new Date().getTime() / 1000
            //     ) +
            //     " seconds"
            // );
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
    }, 5000);
  },
  async userInfo(context) {
    return new Promise((resolve) => {
      instance.get("auth/userinfo").then(async (res) => {
        console.log(res);
        context.commit("userInfo", res.data);
        // await instance.get(`users/${res.data.sub}`).then(async (result) => {
        //   context.commit("userInfo", result.data);
        // });
        instance.get("auth/user/assigned/roles").then((res) => {
          context.commit("userInfoRoles", res.data);
          resolve(10);
        });
        // await instance
        //   .get(`users/${res.data.sub}/roles`)
        //   .then(async (result) => {
        //     context.commit("userInfoRoles", result.data);
        //     await Promise.all(
        //       result.data.map(async () => {
        //         // await instance
        //         //   .get(`roles/${el.name}/permissions`)
        //         //   .then((res) => {
        //         //     context.commit("rolesUserInfo", res.data);
        //         //   });
        //       })
        //     );
        //     console.log("done");
        //     resolve(10);
        //   });
      });
    });
  },
  async clientInfo(context, { userId = "", checkapp = false }) {
    await instance
      .get(`client-info?userId=${userId}&checkapp=${checkapp}`)
      .then((res) => {
        if (userId) {
          context.commit("companyInfo", res.data);
        } else {
          context.commit("clientInfo", res.data);
        }
      });
  },
  // async clientInfoById(content, id) {
  //   await instance.get(`client-info/${id}`).then((res) => {
  //     content.commit("companyInfo", res.data);
  //   });
  // },
  async updateClientInfo(context, data) {
    await instance
      .put(`client-info/${data.id}`, data, {
        headers: {
          "If-Match": data.version,
        },
      })
      .then((res) => {
        context.commit("clientInfo", res.data);
      });
  },
  async createClientInfo(context, data) {
    await instance.post("client-info", data).then((res) => {
      context.commit("clientInfo", res.data);
    });
  },
  async notifications(context, userId) {
    await instance.get(`notifications?userid=${userId}`).then((res) => {
      context.commit("notifications", res.data);
    });
  },
  async roles(context) {
    await instance.get("roles").then((res) => {
      context.commit("roles", res.data);
    });
  },
  async createRole(context, role) {
    return new Promise((resolve, reject) => {
      instance
        .post("roles", role, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async addPermissionToRole(context, data) {
    await instance
      .post(`roles/${data.name}/permissions`, data.permissions)
      .then(() => {});
  },
  async deletePermissionFromRole(context, data) {
    console.log(data.permissions);
    await instance
      .delete(`roles/${data.name}/permissions`, {
        data: data.permissions,
      })
      .then(() => {});
  },
  async rolePermissions(context, role) {
    await instance.get(`roles/${role}/permissions`).then(async (res) => {
      await context.commit("rolePermissions", {
        data: res.data,
        role,
      });
    });
  },
  async users(context, search = "") {
    await instance.get(`users?search=${search}`).then((res) => {
      context.commit("users", res.data);
    });
  },
  async createUser(context, user) {
    return new Promise((resolve, reject) => {
      instance
        .post("users", user)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateUser(context, user) {
    return new Promise((resolve, reject) => {
      const id = user.id;
      delete user.id;
      instance
        .put(`users/${id}`, user)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async addRoleToUser(context, data) {
    await instance
      .post(`users/${data.userId}/roles`, data.roles)
      .then(() => {});
  },
  async deleteRoleFromUser(context, data) {
    await instance
      .delete(`users/${data.userId}/roles`, {
        data: data.roles,
      })
      .then(() => {});
  },
  async userRoles(context, userId) {
    await instance.get(`users/${userId}/roles`).then((res) => {
      context.commit("userRole", {
        data: res.data,
        userId,
      });
    });
  },
  async permissions(context) {
    await instance.get("permissions").then((res) => {
      context.commit("permissions", res.data);
    });
  },
  async adminPermissions(context) {
    await instance.get("permissions/admin").then((res) => {
      context.commit("adminPermissions", res.data);
    });
  },
  async schemes(context, status = "") {
    await instance
      .get(`certification/categories?status=${status}`)
      .then((res) => {
        context.commit("schemes", res.data);
      });
  },
  async schemeById(context, id) {
    await instance.get(`certification/categories/${id}`).then((res) => {
      console.log(res);
    });
  },
  async applications(context, { status = "", userId = "" }) {
    await instance
      .get(`applications?status=${status}&userId=${userId}`)
      .then((res) => {
        console.log(res);
        context.commit("applications", res.data);
      });
  },
  async updateStatusApplication(context, data) {
    return new Promise((resolve, reject) => {
      instance
        .put(`applications/${data.id}/status`, data.status, {
          headers: {
            "Content-Type": "text/plain",
            "If-Match": data.version,
          },
        })
        .then((res) => {
          console.log("success");
          resolve(res);
        })
        .catch((error) => {
          console.log("error");
          reject(error);
        });
    });
  },
  async getClientByApplicationId(context, applicationId) {
    await instance
      .get(`client-info/application/${applicationId}`)
      .then((res) => {
        context.commit("addCompanyToApplication", {
          applicationId,
          data: res.data,
        });
      });
  },
  async contracts(context) {
    await instance.get("planner-service/contracts").then((res) => {
      if (res) {
        context.commit("contracts", res.data);
      }
    });
  },
  async cases(context, { userId = "", status = "", invoiceStatus = "" }) {
    await instance
      .get(
        `planner-service/cases?userId=${userId}&status=${status}&invoicestatus=${invoiceStatus}`
      )
      .then((res) => {
        context.commit("cases", res.data);
      });
  },
  async dialogCases(context, status = "") {
    await instance.get(`planner-service/cases?status=${status}`).then((res) => {
      context.commit("dialogCases", res.data);
    });
  },
  async userByRole(context, role) {
    await instance.get(`roles/${role}/users`).then((res) => {
      context.commit("users", res.data);
    });
  },
  async userDataById(context, id) {
    await instance.get(`users/${id}`).then((res) => {
      context.commit("userDataId", res.data);
    });
  },
  async userById(context, data) {
    await instance.get(`users/${data.userId}`).then((res) => {
      context.commit("addAuditorToCase", {
        caseId: data.caseId,
        data: res.data,
      });
    });
  },
  async userByEmail(context, email) {
    return new Promise((resolve, reject) => {
      instance
        .get(`users/?email=${email}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createScheme(context, scheme) {
    return new Promise((resolve, reject) => {
      instance
        .post("certification/categories", scheme)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSchema(context, scheme) {
    return new Promise((resolve, reject) => {
      instance
        .put(`certification/categories/${scheme.id}`, scheme, {
          headers: {
            "If-Match": scheme.version,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createContract(context, contract) {
    return new Promise((resolve, reject) => {
      instance
        .post("planner-service/contracts", contract)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateContract(context, contract) {
    return new Promise((resolve, reject) => {
      instance
        .put(`planner-service/contracts/${contract.id}`, contract, {
          headers: {
            "If-Match": contract.version,
          },
        })
        .then((res) => {
          resolve(res);
          context.commit("setContract", res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async certificationForCase(context, payload) {
    await instance
      .get(`certification/categories/${payload.certificationId}`)
      .then((res) => {
        context.commit("addCertificationToCase", {
          caseId: payload.caseId,
          data: res.data,
        });
      });
  },
  async applicationForCase(context, payload) {
    await instance
      .get(`client-info/application/${payload.applicationId}`)
      .then((res) => {
        context.commit("addCompanyToCase", {
          caseId: payload.caseId,
          data: res.data,
        });
      });
  },
  async auditPlans(context) {
    await instance.get("audit-service/audit-plans").then((res) => {
      context.commit("auditPlans", res.data);
    });
  },
  async createAuditPlan(context, data) {
    return new Promise((resolve, reject) => {
      instance
        .post("audit-service/audit-plans", data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // async caseAndApplicationForPlan(context, data) {
  //   await instance
  //     .get(`planner-service/cases/${data.caseId}`)
  //     .then(async (res) => {
  //       context.commit("addCaseToPlan", {
  //         planId: data.planId,
  //         data: res.data,
  //       });

  //       await instance
  //         .get(`client-info/application/${res.data.applicationId}`)
  //         .then((res) => {
  //           context.commit("addCompanyToPlan", {
  //             planId: data.planId,
  //             data: res.data,
  //           });
  //         });
  //     });
  // },
  async createApplication(context, data) {
    await instance.post("applications", data).then((res) => {
      context.commit("setApplicationPdf", res.data);
    });
  },
  async applicationById(context, id) {
    await instance.get(`applications/${id}`).then((res) => {
      context.commit("setApplicationPdf", res.data);
    });
  },
  async updateApplication(context, { value, comment = false }) {
    await instance
      .put(`applications/${value.id}`, value, {
        headers: {
          "If-Match": value.version,
        },
      })
      .then((res) => {
        if (!comment) {
          context.commit("setApplicationPdf", res.data);
        } else {
          context.commit("setApplication", res.data);
        }
      });
  },
  async applicationPdf(context, id) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/application/submit?applicationId=${id}&jwt=${this.app.config.globalProperties.$keycloak.token}`;
    var fileLink = document.createElement("a");
    fileLink.href = url;
    fileLink.setAttribute("download", "application.pdf");
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async uploadFile(context, data) {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("type", data.type);
    switch (data.type) {
      case "application":
        formData.append("applicationId", data.id);
        break;
      case "case":
        formData.append("caseId", data.id);
        break;
      case "invoice":
        formData.append("caseId", data.id);
        break;
      case "contract":
        formData.append("contractId", data.id);
        break;
      default:
        break;
    }

    await instance.post("resource-service/upload", formData, {
      "Content-type": "multipart/form-data",
    });
  },
  async files(context, { type = "", id = "" }) {
    await instance
      .get(`resource-service/files?fileType=${type}&id=${id}`)
      .then((res) => {
        if (res) {
          context.commit("files", res.data);
        }
      });
  },
  async auditTask(context, data) {
    await instance
      .put(
        `audit-service/audit-plans/${data.id}/audit-sections`,
        data.sections,
        {
          headers: {
            "If-Match": data.version,
          },
        }
      )
      .then((res) => {
        context.commit("auditSection", { data: res.data, caseId: data.caseId });
      });
  },
  async updateAuditPlan(context, data) {
    await instance.put(
      `audit-service/audit-plan/${data.id}`,
      data.updateObject,
      {
        headers: {
          "If-Match": data.version,
        },
      }
    );
  },
  async downloadFile(context, { data, download = true }) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/files/${data.id}?jwt=${this.app.config.globalProperties.$keycloak.token}`;
    // const blob = new Blob([res.data], { type: "application/pdf" });
    // var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
    if (download) {
      var fileLink = document.createElement("a");
      // fileLink.setAttribute("type", "application/pdf");
      fileLink.href = url;
      fileLink.setAttribute("download", "");
      document.body.appendChild(fileLink);
      fileLink.click();
    } else {
      // fileLink.setAttribute("target", "_blank");
      context.commit("fileToView", url);
    }
  },
  async certifications(context, { userId = "", certId = "", excludeRenewals = false }) {
    await instance
      .get(
        `planner-service/certifications${
          certId ? `/${certId}` : ""
        }?userId=${userId}&excludeRenewals=${excludeRenewals}`
      )
      .then((res) => {
        context.commit("certifications", res.data);
      });
  },
  async downloadCertification(context, { id, download = true, language }) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/certification/${id}?lang=${language}&jwt=${this.app.config.globalProperties.$keycloak.token}`;
    // const blob = new Blob([res.data], { type: "application/pdf" });
    // var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
    if (download) {
      var fileLink = document.createElement("a");
      // fileLink.setAttribute("type", "application/pdf");
      fileLink.href = url;
      fileLink.setAttribute("download", "");
      document.body.appendChild(fileLink);
      fileLink.click();
    } else {
      // fileLink.setAttribute("target", "_blank");
      context.commit("fileToView", url);
    }
  },
  async updateCaseStatus(context, { id, version, status }) {
    return new Promise((resolve, reject) => {
      instance
        .put(
          `planner-service/cases/${id}/status`,
          { status },
          {
            headers: {
              "If-Match": version,
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateCase(context, data) {
    return new Promise((resolve, reject) => {
      instance
        .put(`planner-service/cases/${data.id}`, data, {
          headers: {
            "If-Match": data.version,
          },
        })
        .then((res) => {
          context.commit("setCase", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async availableAuditors(context, dates) {
    await instance
      .get(
        `planner-service/cases/auditors/availability?startDate=${dates.startDate}&endDate=${dates.endDate}`
      )
      .then((res) => {
        context.commit("availableAuditors", res.data);
      });
  },
  async contractView(context, id) {
    // await instance
    //   .get(`resource-service/contract/${id}`, { responseType: "blob" })
    //   .then((res) => {
    //     const blob = new Blob([res.data], { type: "application/pdf" });
    //     var data = window.URL.createObjectURL(blob);
    //   });
    context.commit(
      "fileToView",
      `${process.env.VUE_APP_BACK_URL}resource-service/contract/${id}`
    );
  },
  async templates(context) {
    await instance.get("resource-service/templates").then((res) => {
      context.commit("templates", res.data);
    });
  },
  async updateSchemeInstance(context, data) {
    await instance.put(`scheme/instance/${data.id}`, data).then((res) => {
      context.commit("updateSchemeInstance", res.data);
    });
  },
  async updateAuditPlanStatus(context, data) {
    await instance.put(
      `audit-service/audit-plan/status/${data.id}`,
      { status: data.status },
      {
        headers: {
          "If-Match": data.version,
        },
      }
    );
  },
  async updateCommentCase(context, data) {
    await instance
      .put(
        `planner-service/cases/${data.id}/comments`,
        { comments: data.comments },
        {
          headers: {
            "If-Match": data.version,
          },
        }
      )
      .then((res) => {
        context.commit("setCase", res.data);
      });
  },
  async uploadTemplate(content, data) {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("type", data.type);
    formData.append("name", data.file.name);
    return new Promise((resolve) => {
      instance.post("resource-service/templates", formData).then((res) => {
        resolve(res.data);
      });
    });
  },
  async auditorsAvailability(context, data) {
    await instance
      .get(
        `planner-service/cases/auditors/availability/status?startDate=${data.startDate}&endDate=${data.endDate}`
      )
      .then((res) => {
        context.commit("auditorsAvailability", res.data);
      });
  },
  async assignToCase(context, data) {
    const id = data.id;
    const version = data.version;
    delete data.id;
    delete data.version;
    await instance
      .put(`planner-service/cases/${id}/assigners`, data, {
        headers: {
          "If-Match": version,
        },
      })
      .then((res) => {
        context.commit("setCase", res.data);
      });
  },
  async assignToApplication(context, { id, version, applicationReviewerId }) {
    await instance
      .put(
        `applications/${id}/reviewer`,
        { applicationReviewerId },
        {
          headers: {
            "If-Match": version,
          },
        }
      )
      .then((res) => {
        context.commit("setApplication", res.data);
      });
  },
  async userProfileById(context, userId) {
    let userData = await instance.get(`users/${userId}`);
    let userRoles = await instance.get(`users/${userId}/roles`);
    let userDataById = { ...userData.data, roles: userRoles.data, company: {} };
    let companyData = {};
    console.log(
      userRoles.data.some((el) => {
        return el.name == "client";
      })
    );
    if (
      userRoles.data.some((el) => {
        return el.name == "client";
      })
    ) {
      companyData = await instance.get(`client-info?userId=${userId}`);
      userDataById.company = companyData.data[0];
    }
    context.commit("userProfileById", userDataById);
  },
  async addFileToSchemeInstance(context, { id, name, file }) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    await instance.put(`scheme/instance/${id}/files`, formData);
  },
  // async getClientInfoDropdown(context, type) {
  //   await instance
  //     .get(`client-info/permitted/values?type=${type}`)
  //     .then((res) => {
  //       if (type == "country") {
  //         context.commit("countries", res.data);
  //       } else if (type == "companyType") {
  //         context.commit("companyTypes", res.data);
  //       } else if (type == "doy") {
  //         context.commit("doy", res.data);
  //       }
  //     });
  // },
  async doy(context) {
    await instance.get("doy").then((res) => {
      if (res) {
        context.commit("doy", res.data);
      }
    });
  },
  async companyTypes(context) {
    await instance.get("kad").then((res) => {
      if (res) {
        context.commit("companyTypes", res.data);
      }
    });
  },
  async countries(context) {
    await instance.get("countries").then((res) => {
      if (res) {
        context.commit("countries", res.data);
      }
    });
  },
  async cities(context) {
    await instance.get("cities").then((res) => {
      if (res) {
        context.commit("cities", res.data);
      }
    });
  },
  async updateDoy(context, doy) {
    let formData = new FormData();
    formData.append("file", doy);
    await instance.post("doy", formData).then((res) => {
      if (res) {
        context.commit("doy", res.data);
      }
    });
  },
  async updateCompanyTypes(context, companyTypes) {
    let formData = new FormData();
    formData.append("file", companyTypes);
    await instance.post("kad", formData).then((res) => {
      if (res) {
        context.commit("companyTypes", res.data);
      }
    });
  },
  async updateCountries(context, countries) {
    let formData = new FormData();
    // formData.append("file", new Blob([new Uint8Array(countries).buffer]), "countries.json", {
    //   type: "application/json"
    // });
    formData.append("file", countries);
    await instance.post("countries", formData).then((res) => {
      if (res) {
        context.commit("countries", res.data);
      }
    });
  },
  async updateCities(context, cities) {
    let formData = new FormData();
    formData.append("file", cities);
    await instance.post("cities", formData).then((res) => {
      if (res) {
        context.commit("cities", res.data);
      }
    });
  },
  async uploadFilesSchemeInstance(context, data) {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("name", data.file.name);
    formData.append("filetype", data.file.type);
    formData.append("userId", data.userId);
    await instance.put(
      `resource-service/scheme/instance/${data.id}/files`,
      formData
    );
  },
  // async schemeInstanceFiles(context, fileId) {
  //   await instance
  //     .get(`resource-service/scheme/instance/files/${fileId}`)
  //     .then((res) => {
  //       context.commit("auditCaseFiles", res.data);
  //     });
  // },
  async updateRoleName(context, { originalName, newName }) {
    await instance.put(`roles/${originalName}`, newName, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
  },
  async addRating(context, data) {
    await instance.put(`planner-service/cases/${data.id}/ratings`, {
      rating: data.rating,
      ratingDate: this.app.config.globalProperties
        .$dayjs()
        .format("YYYY-MM-DD"),
      ratingComments: data.comments,
    });
  },
  async getSchemeInstanceFile(context, schemeInstanceId) {
    await instance
      .get(`resource-service/scheme/instance/${schemeInstanceId}/files`)
      .then((res) => {
        context.commit("auditCaseFiles", res ? res.data : []);
      });
  },
  async downloadSchemeInstanceFile(context, file) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/scheme/instance/files/${file.id}?jwt=${this.app.config.globalProperties.$keycloak.token}`;
    // const blob = new Blob([res.data], { type: "application/pdf" });
    // var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
    var fileLink = document.createElement("a");
    // fileLink.setAttribute("type", "application/pdf");
    fileLink.href = url;
    fileLink.setAttribute("download", file.name);
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async invoiceView(context, id) {
    await instance
      .get(`resource-service/files/${id}`, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        var data = window.URL.createObjectURL(blob);
        context.commit("invoiceView", data);
      });
  },
  async renewals(context) {
    await instance.get("planner-service/renewals").then((res) => {
      if (res) {
        context.commit("renewals", res.data);
      }
    });
  },
  async updateRenewal(context, data) {
    await instance.put(`planner-service/renewals/${data.id}`, data).then(() => {
      context.commit("setRenewal", data);
    });
  },
  async addApplicationComment(context, data) {
    return new Promise((resolve, reject) => {
      instance
        .put(
          `applications/${data.id}/comments`,
          { comments: data.comments },
          {
            headers: {
              "If-Match": data.version,
            },
          }
        )
        .then((res) => {
          context.commit("setApplication", res.data);
          resolve(res.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  async updateTemplate(content, data) {
    let formData = new FormData();
    console.log(data);
    formData.append("file", data.file);
    formData.append("type", data.type);
    formData.append("name", data.file.name);
    formData.append("status", false);
    await instance.put(`resource-service/templates/${data.id}`, formData);
  },
  action(context, data) {
    instance.post("actions", data);
  },
  async removeReviewer(context, data) {
    await instance
      .put(
        `applications/${data.id}/remove/reviewer`,
        {},
        {
          headers: {
            "If-Match": data.version,
          },
        }
      )
      .then((res) => {
        context.commit("setApplication", res.data);
      });
  },
  async getActions(context, userId) {
    await instance.get(`actions?userid=${userId}`).then((res) => {
      context.commit("actions", res.data);
    });
  },
  async renewalsDropDown(context, type) {
    await instance
      .get(`planner-service/renewals/dropdown?type=${type}`)
      .then((res) => {
        context.commit("renewalDropdown", { type, data: res.data });
      });
  },
  async getUsersRegisteredDashboard(context, date) {
    await instance.get(`dashboard/users?date=${date}`).then((res) => {
      context.commit("usersRegisteredDashboard", res.data);
    });
  },
  async getApplicationsDashboard(context, date) {
    await instance.get(`dashboard/applications?date=${date}`).then((res) => {
      context.commit("applicationsDashboard", res.data);
    });
  },
  async getCertificationsDashboard(context) {
    await instance.get(`dashboard/certifications`).then((res) => {
      context.commit("certificationsDashboard", res.data);
    });
  },
  async getCasesDashboard(context) {
    await instance.get(`dashboard/cases`).then((res) => {
      context.commit("casesDashboard", res.data);
    });
  },
  async getAuditPlansDashboard(context) {
    await instance.get(`dashboard/auditplans`).then((res) => {
      context.commit("auditPlansDashboard", res.data);
    });
  },
  async getCompaniesDashboard(context) {
    await instance.get(`dashboard/companies`).then((res) => {
      context.commit("companiesDashboard", res.data);
    });
  },
  async getCitiesDashboard(context) {
    await instance.get(`dashboard/cities`).then((res) => {
      context.commit("citiesDashboard", res.data);
    });
  },
  async getVisitsDashboard(context) {
    await instance.get(`dashboard/visits`).then((res) => {
      context.commit("visitsDashboard", res.data);
    });
  },
  async updateNotification(context, { id, read, pinned }) {
    await instance.put(`notifications/${id}`, { readByAdmin: read, pinned });
  },
  async usersRegistrationsDashboardPeriod(context, { date, dateEnd }) {
    await instance
      .get(`dashboard/users?date=${date}&dateEnd=${dateEnd}`)
      .then((res) => {
        context.commit("registeredGraphDashboard", res.data);
      });
  },
  async auditPlansDashboardPeriod(context, { date, dateEnd }) {
    await instance
      .get(`dashboard/auditplans?date=${date}&dateEnd=${dateEnd}`)
      .then((res) => {
        context.commit("auditPlansGraphDashboard", res.data);
      });
  },
  async updateUserList() {
    await instance.get("users/system/get/from/keycloak");
  },
  async deleteAccount(context, userId) {
    await instance.get(
      `resource-service/gdpr/user/${userId}?jwt=${this.app.config.globalProperties.$keycloak.token}`
    );
    // const url = `${process.env.VUE_APP_BACK_URL}resource-service/gdpr/user/${userId}?jwt=${this.app.config.globalProperties.$keycloak.token}`;

    // var fileLink = document.createElement("a");
    // fileLink.href = url;
    // fileLink.setAttribute("download", "");
    // document.body.appendChild(fileLink);
    // fileLink.click();
  },
  async checkDeletedAccount(context, userId) {
    await instance.get(`users/system/test/${userId}`).then((res) => {
      context.commit("deleteAccount", res.data.deleted);
    });
  },
  async downloadDeletedAccountFile(context, userId) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/gdpr/files/user/${userId}?jwt=${this.app.config.globalProperties.$keycloak.token}`;

    var fileLink = document.createElement("a");
    fileLink.href = url;
    fileLink.setAttribute("download", "");
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async applicationsForSales(context) {
    await instance.get("applications/for/sales/dasboard").then((res) => {
      context.commit("applications", res.data);
    });
  },
  async actionsObject(context) {
    await instance.get("actiontypes").then((res) => {
      context.commit("actionsObject", res.data);
    });
  },
  async updateActionsObject(context, actions) {
    let formData = new FormData();
    formData.append("file", actions);
    await instance.post("actiontypes", formData).then((res) => {
      if (res) {
        context.commit("actionsObject", res.data);
      }
    });
  },
  async notificationsObject(context) {
    await instance.get("notificationtypes").then((res) => {
      context.commit("notificationsObject", res.data);
    });
  },
  async updateNotificationsObject(context, notifications) {
    let formData = new FormData();
    formData.append("file", notifications);
    await instance.post("notificationtypes", formData).then((res) => {
      if (res) {
        context.commit("notificationsObject", res.data);
      }
    });
  },
  async getRenewalTime(context) {
    await instance.get("planner-service/renewal-time").then((res) => {
      if (res) {
        context.commit("renewalTime", res.data);
      }
    });
  },
  async saveRenewalTime(context, data) {
    await instance.post("planner-service/renewal-time", data).then((res) => {
      context.commit("renewalTime", res.data);
    });
  },
  async getRatingTime(context) {
    await instance.get("planner-service/rating-time").then((res) => {
      if (res) {
        context.commit("ratingTime", res.data);
      }
    });
  },
  async saveRatingTime(context, data) {
    await instance.post("planner-service/rating-time", data).then((res) => {
      context.commit("ratingTime", res.data);
    });
  },
  async updateLanguage(context, data) {
    await instance.put(`users/${data.id}/language`, {
      language: data.language,
    });
  },
  exportJson(context, { name, json }) {
    let stringJson = JSON.stringify(json, null, "\t");
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:application/json;charset=utf-8,${encodeURIComponent(stringJson)}`
    );
    element.setAttribute("download", `${name}.json`);
    element.click();
  },
  async getActionsGrouped(context, { userId, dateStart, dateEnd }) {
    await instance
      .get(
        `actions/bydate?userid=${userId}&dateStart=${dateStart}&dateEnd=${dateEnd}`
      )
      .then((res) => {
        context.commit("actionsGrouped", res.data);
      });
  },
  async getCoordinates(context, location) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&language=el&key=${process.env.VUE_APP_GOOGLE_KEY}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveRadius(context, radius) {
    await instance.post("validation/radius", { radius });
  },
  async getRadius(context) {
    await instance.get("validation/radius").then((res) => {
      if (res) {
        context.commit("radius", res.data);
      }
    });
  },
  validateCoordinates(context, { latitude, longitude, appId }) {
    return new Promise((resolve, reject) => {
      instance
        .get(
          `applications/${appId}/validate/location?latitude=${latitude}&longitude=${longitude}`
        )
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  async deletePeriod(context) {
    await instance.get("users/get/deletion/period").then((res) => {
      if (res) {
        context.commit("deletePeriod", res.data);
      }
    });
  },
  async saveDeletePeriod(context, deletePeriod) {
    await instance
      .post("users/set/deletion/period", deletePeriod)
      .then((res) => {
        context.commit("deletePeriod", res.data);
      });
  },
  async updateShowRatingValue(context, certId) {
    await instance.put(
      `planner-service/certifications/${certId}/show/rating/popup`,
      { showRatingPopup: false }
    );
  },
  async returnUserById(context, userId) {
    return new Promise((resolve) => {
      instance.get(`users/${userId}`).then((res) => {
        resolve(res.data);
      });
    });
  },
  async downloadTemplate(context, id) {
    const url = `${process.env.VUE_APP_BACK_URL}resource-service/templates/download/${id}?jwt=${this.app.config.globalProperties.$keycloak.token}`;
    // const blob = new Blob([res.data], { type: "application/pdf" });
    // var fileURL = (window.URL || window.webkitURL).createObjectURL(blob);

    const fileLink = document.createElement("a");
    // fileLink.setAttribute("type", "application/pdf");
    fileLink.href = url;
    fileLink.setAttribute("download", "");
    document.body.appendChild(fileLink);
    fileLink.click();
  },
  async bulkExport(context, type) {
    let url = "";
    switch (type) {
      case "countries":
        url = "countries";
        break;
      case "cities":
        url = "cities";
        break;
      case "doy":
        url = "doy";
        break;
      case "kad":
        url = "kad";
        break;
      case "notifications":
        url = "notificationtypes";
        break;
      case "actions":
        url = "actiontypes";
        break;
      default:
        break;
    }
    await instance.get(url).then((res) => {
      let stringJson = JSON.stringify(res.data, null, "\t");
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:application/json;charset=utf-8,${encodeURIComponent(stringJson)}`
      );
      element.setAttribute("download", `${type}.json`);
      element.click();
    });
  },
};
