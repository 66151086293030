<template>
  <div class="layout-sidebar" @click="onMenuClick">
    <router-link :to="{name: 'dashboard'}" class="logo">
      <img
        id="app-logo"
        class="logo-image"
        src="/logo.svg"
        alt="toorbee-logo"
      />
    </router-link>
    <div v-if="screenWidth < 1091" class="flex flex-column px-4 pt-3">
      <p class="profile-name text-black-alpha-90 m-0 text-base font-bold">{{ userInfo.name }}</p>

      <p class="text-black-alpha-90 text-base">{{ getUserRoleTranslated }}</p>
    </div>
    <div class="layout-menu-container">
      <AppSubmenu
        :items="model"
        :layoutMode="layoutMode"
        :parentMenuItemActive="true"
        :menuActive="active"
        :mobileMenuActive="mobileMenuActive"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppSubmenu from "./AppSubmenu";
export default {
  name: "AppMenu",
  emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
  props: {
    model: Array,
    layoutMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
  },
  components: { AppSubmenu },
  computed: {
    ...mapGetters(["userInfo", "getUserRoleTranslated"]),
  },
};
</script>

<style lang="scss" scoped>
.logo-image {
  width: 100%;
  height: 100%;
}

.logo {
  padding: 0;
}
</style>
