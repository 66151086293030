import { app } from "../main";

export default {
  login() {
    // Cookies.set('SESSION',store.app.config.globalProperties.$keycloak);
  },
  userInfo(state, payload) {
    Object.assign(state.userInfo, payload);
  },
  clientInfo(state, payload) {
    state.clientInfo = payload[0];
  },
  companyInfo(state, payload) {
    state.clientInfoId = payload;
  },
  notifications(state, payload) {
    state.notifications = payload.filter((el) => el.readByAdmin == false);
  },
  userInfoRoles(state, payload) {
    // Object.assign(state.userInfo, payload);
    state.userInfo.roles = [];
    payload.forEach((el) => {
      state.userInfo.roles.push(el.name);
    });
    // state.userInfo.roles = payload.claims.realm_access.roles;
    if (
      state.userInfo.roles.length == 1 &&
      state.userInfo.roles[0] == "default-roles-microservices-realm"
    ) {
      state.userInfo.roles.push("client");
    }
    state.userInfo.permissions = [];
  },
  rolesUserInfo(state, payload) {
    state.userInfo.permissions.push(...payload);
  },
  roles(state, payload) {
    payload.forEach((el) => {
      el.translatedName = app.config.globalProperties.$t(
        `userRoles.${el.name}`
      );
    });
    state.roles = payload;
  },
  rolePermissions(state, payload) {
    let tempRole = state.roles.find((el) => el.name == payload.role);
    tempRole.permissions = payload.data;
  },
  users(state, payload) {
    state.users = payload;
  },
  emptyUsers(state) {
    state.users = [];
  },
  userRole(state, payload) {
    let user = state.users.find((el) => el.id == payload.userId);
    user.roles = payload.data;
  },
  permissions(state, payload) {
    state.permissions = payload;
  },
  adminPermissions(state, payload) {
    state.permissions.push(...payload);
  },
  schemes(state, payload) {
    payload.forEach(el => {
      el.tempCreatedDate = app.config.globalProperties
            .$dayjs(el.createdDate, "DD/MM/YYYY")
            .valueOf()
    })
    state.certCategories = payload;
  },
  applications(state, payload) {
    state.applications = payload;

    state.applications.forEach((el) => {
      let contract = state.contracts.find((el1) => el1.applicationId == el.id);
      el.contract = contract ? contract : {};
      el.contractStatus = contract?.status;
      el.contractExtraCost = contract?.extraCost;
      el.contractPrice = contract?.price;
      el.createdDate = app.config.globalProperties
            .$dayjs(el.createdDate, "DD/MM/YYYY")
            .valueOf();
      el.desiredAuditDate = app.config.globalProperties
            .$dayjs(el.desiredAuditDate, "DD/MM/YYYY")
            .valueOf();
      el.approvalRejectionDate = app.config.globalProperties
            .$dayjs(el.approvalRejectionDate, "DD/MM/YYYY")
            .valueOf();
    });
  },
  addCompanyToApplication(state, payload) {
    let application = state.applications.find(
      (el) => el.id == payload.applicationId
    );
    application.companyName = payload.data.companyName;
    application.company = payload.data;
  },
  contracts(state, payload) {
    payload.forEach(contract => {
      contract.createdDate = app.config.globalProperties
            .$dayjs(contract.createdDate, "DD/MM/YYYY")
            .valueOf()
    });
    state.contracts = payload;
  },
  cases(state, payload) {
    payload.forEach((caseValue) => {
      let datesArray = [];
      caseValue.statusTimestamps.forEach((timestamp) => {
        datesArray.push(
          app.config.globalProperties
            .$dayjs(timestamp.createdAt, "DD/MM/YYYY")
            .valueOf()
        );
      });
      const maxValue = Math.max.apply(null, datesArray);
      caseValue.approvalRejectionComparison = maxValue > 0 ? maxValue : -1;
      caseValue.tempAuditDate =  app.config.globalProperties.$dayjs(caseValue.auditDate,"DD/MM/YYYY").format("MM/DD/YYYY");
      caseValue.tempInvoicePaidDate =  app.config.globalProperties.$dayjs(caseValue.invoicePaidDate,"DD/MM/YYYY").format("MM/DD/YYYY");
      caseValue.tempRatingDate =  app.config.globalProperties.$dayjs(caseValue.ratingDate,"DD/MM/YYYY").valueOf();
    });

    payload.sort(
      (a, b) => new Date(b.lastModifiedDate) - new Date(a.lastModifiedDate)
    );
    state.cases = payload;
  },
  addCertificationToCase(state, payload) {
    let caseObject = state.cases.find((el) => el.id == payload.caseId);
    caseObject.certification = payload.data;
  },
  addCompanyToCase(state, payload) {
    let caseObject = state.cases.find((el) => el.id == payload.caseId);
    caseObject.company = payload.data;
  },
  addAuditorToCase(state, payload) {
    let caseObject = state.cases.find((el) => el.id == payload.caseId);
    if (!("auditorsData" in caseObject)) {
      caseObject.auditorsData = [];
    } else if (caseObject.auditorsData.length == caseObject.auditors.length) {
      caseObject.auditorsData = [];
    }
    caseObject.auditorsData.push(payload.data);
  },
  auditPlans(state, payload) {
    this.state.auditPlans = payload;
  },
  addCaseToPlan(state, payload) {
    let planObject = state.auditPlans.find((el) => el.id == payload.planId);
    planObject.case = payload.data;
  },
  addCompanyToPlan(state, payload) {
    let planObject = state.auditPlans.find((el) => el.id == payload.planId);
    planObject.company = payload.data;
  },
  setApplicationPdf(state, payload) {
    state.applicationPdf = payload;
  },
  files(state, payload) {
    payload.forEach(file => {
      file.tempCreatedDate = app.config.globalProperties
            .$dayjs(file.createdDate, "DD/MM/YYYY")
            .valueOf();
    });
    state.files = payload;
  },
  auditSection(state, payload) {
    Object.assign(
      state.cases[state.cases.findIndex((el) => el.id == payload.caseId)]
        .auditPlan,
      payload.data
    );
  },
  setApplication(state, payload) {
    Object.assign(
      state.applications[
        state.applications.findIndex((el) => el.id == payload.id)
      ],
      payload
    );
  },
  setCase(state, payload) {
    Object.assign(
      state.cases[state.cases.findIndex((el) => el.id == payload.id)],
      payload
    );
  },
  certifications(state, payload) {
    if(Array.isArray(payload)) {
      payload.forEach(certification => {
        certification.tempCertificationDate = app.config.globalProperties
              .$dayjs(certification.certificationDate, "DD/MM/YYYY")
              .valueOf();
      });
    }
    state.certifications = payload;
  },
  availableAuditors(state, payload) {
    state.availableAuditors = payload;
  },
  templates(state, payload) {
    payload.forEach(template => {
      template.tempCreatedAt = app.config.globalProperties
            .$dayjs(template.createdAt, "DD/MM/YYYY")
            .valueOf();
    });
    state.templates = payload;
  },
  dialogCases(state, payload) {
    state.dialogCases = payload;
  },
  auditorsAvailability(state, payload) {
    state.auditorsAvailability = payload;
  },
  userDataId(state, payload) {
    state.userDataId = payload;
  },
  userProfileById(state, payload) {
    payload.roles = payload.roles.filter(
      (role) =>
        role.name != "default-roles-microservices-realm" &&
        role.name != "offline_access" &&
        role.name != "moderator" &&
        role.name != "uma_authorization"
    );
    state.userProfileById = payload;
  },
  countries(state, payload) {
    state.countries = payload;
  },
  companyTypes(state, payload) {
    state.companyTypes = payload;
  },
  doy(state, payload) {
    state.doyOptions = payload;
  },
  auditCaseFiles(state, payload) {
    state.auditCaseFiles = payload;
  },
  deleteAuditCaseFiles(state) {
    state.auditCaseFiles = [];
  },
  invoiceView(state, payload) {
    state.invoiceView = payload;
  },
  renewals(state, payload) {
    payload.forEach(renewal => {
      renewal.tempCertificationDate = app.config.globalProperties
            .$dayjs(renewal.certificationDate, "DD/MM/YYYY")
            .valueOf();
      renewal.tempCertificationExpirationDate = app.config.globalProperties
            .$dayjs(renewal.certificationExpirationDate, "DD/MM/YYYY")
            .valueOf();
      const tempLastContactDate = app.config.globalProperties
      .$dayjs(renewal.lastContactDate, "DD/MM/YYYY")
      .valueOf();
      renewal.tempLastContactDate = tempLastContactDate ? tempLastContactDate : -1
    });
    state.renewals = payload;
  },
  actions(state, payload) {
    payload.unshift({});
    state.actions = payload;
  },
  fileToView(state, payload) {
    state.fileToView = payload;
  },
  renewalDropdown(state, payload) {
    state.renewalDropdown[payload.type] = payload.data;
  },
  setConnected(state, payload) {
    state.setConnected = payload;
  },
  usersRegisteredDashboard(state, payload) {
    state.usersRegisteredDashboard = payload;
  },
  applicationsDashboard(state, payload) {
    state.applicationsDashboard = payload;
  },
  certificationsDashboard(state, payload) {
    state.certificationsDashboard = payload;
  },
  casesDashboard(state, payload) {
    state.casesDashboard = payload;
  },
  auditPlansDashboard(state, payload) {
    state.auditPlansDashboard = payload;
  },
  companiesDashboard(state, payload) {
    state.companiesDashboard = payload;
  },
  citiesDashboard(state, payload) {
    state.citiesDashboard = payload;
  },
  visitsDashboard(state, payload) {
    state.visitsDashboard = payload;
  },
  removeNotification(state, index) {
    console.log("remove notifications:" + index);
    state.notifications[index].readByAdmin = true;
  },
  addNotification(state, notification) {
    state.notifications.unshift(notification);
  },
  setRenewal(state, payload) {
    Object.assign(
      state.renewals[state.renewals.findIndex((el) => el.id == payload.id)],
      payload
    );
  },
  registeredGraphDashboard(state, payload) {
    state.registeredGraphDashboard = payload;
  },
  auditPlansGraphDashboard(state, payload) {
    state.auditPlansGraphDashboard = payload;
  },
  deleteAccount(state, payload) {
    state.deleteAccount = payload;
  },
  pinUnpinNotification(state, index) {
    console.log("pin or unpin notifications:" + index);
    state.notifications[index].pinned = !state.notifications[index].pinned;
  },
  screenWidth(state, width) {
    state.screenWidth = width;
  },
  cities(state, payload) {
    state.cities = payload;
  },
  actionsObject(state, payload) {
    state.actionsObject = payload;
  },
  notificationsObject(state, payload) {
    state.notificationsObject = payload;
  },
  renewalTime(state, payload) {
    state.renewalTime = payload;
  },
  ratingTime(state, payload) {
    state.ratingTime = payload;
  },
  actionsGrouped(state, payload) {
    if (payload.length > 1) {
      payload.forEach((el, index) => {
        if (index < payload.length - 1) {
          el.actions.push({});
        }
      });
    }
    state.actionsGrouped = payload;
  },
  radius(state, payload) {
    state.radius = payload;
  },
  deletePeriod(state, payload) {
    state.deletePeriod = payload;
  },
  setContract(state, payload) {
    Object.assign(
      state.contracts[state.contracts.findIndex((el) => el.id == payload.id)],
      payload
    );
  },
  updateSchemeInstance(state, payload) {
    Object.assign(
      state.cases[
        state.cases.findIndex((el) => el.schemeInstance.id == payload.id)
      ].schemeInstance,
      payload
    );
  },
};
