<template>
  <div
    class="p-message p-component"
    :class="severity(notification).color"
    role="alert"
    v-show="notification.readByAdmin == false"
  >
    <div class="p-message-wrapper">
      <span
        class="p-message-icon pi"
        :class="severity(notification).icon"
      ></span>
      <div class="p-message-text">
        <div v-html="notification.message"></div>
      </div>
      <div class="ml-auto flex flex-column md:flex-row">
        <button
          @click="pinUnpinNotification(notification, index)"
          class="p-message-close p-link"
          type="button"
        >
          <i
            class="p-message-close-icon mdi"
            :class="notification.pinned ? 'mdi-pin-off' : 'mdi-pin'"
          ></i
          ><span class="p-ink"></span>
        </button>
        <button
          @click="readNotification(notification, index)"
          class="p-message-close p-link"
          type="button"
          :disabled="notification.pinned"
        >
          <i class="p-message-close-icon pi pi-times"></i
          ><span class="p-ink"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification message",
  props: ["notification", "index"],
  methods: {
    readNotification(notification, index) {
      this.$nextTick(() => {
        this.$store.commit("removeNotification", index);
      });
      this.$store.dispatch("updateNotification", {
        id: notification.id,
        read: true,
        pinned: notification.pinned
      });
    },
    pinUnpinNotification(notification, index) {
      this.$nextTick(() => {
        this.$store.commit("pinUnpinNotification", index);
      });
      this.$store.dispatch("updateNotification", {
        id: notification.id,
        read: notification.readByAdmin,
        pinned: !notification.pinned
      });
    },
    severity(val) {
      let body = {};
      switch (val.severity) {
        case "1":
          body.icon = "pi-check";
          body.color = "p-message-success";
          break;
        case "2":
          body.icon = "pi-info-circle";
          body.color = "p-message-info";
          break;
        case "3":
          body.icon = "pi-exclamation-triangle";
          body.color = "p-message-warn";
          break;
        case "4":
          body.icon = "pi-times-circle";
          body.color = "p-message-error";
          break;
      }
      return body;
    },
  },
};
</script>
