export default {
        install(app) {
                app.config.globalProperties.$formatDate = (date) => {
                        if(date) {
                                let value = new Date(date);
                                return value.toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                });
                        }

                        return app.config.globalProperties.$t("global.noDate");
                }
        }
}