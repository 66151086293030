export default {
  install(app) {
    //     /**
    //      * @typedef {"keyvalue" | "bar" | "timeseries" | "pie" | "table"} action
    //      */

    //     /**
    //      * @param action {action}
    //      * @param extraData
    //      */
    function action(action, extraData, type, usernameValue, useridValue, roleValue) {
      const role = roleValue ? roleValue : app.config.globalProperties.$store.getters.getUserRole[0];
      const userid = useridValue ? useridValue : app.config.globalProperties.$store.getters.userInfo.sub;
      const username =
      usernameValue ? usernameValue : app.config.globalProperties.$store.getters.userInfo.preferred_username;
      let extras = "";
      switch (type) {
        case "application":
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/applications?appId=${extraData.applicationId}`;
          extraData.linkclient = `${process.env.VUE_APP_FRONT_URL}/applications?appId=${extraData.applicationId}`;
          extraData.linksales = `${process.env.VUE_APP_FRONT_URL}/createContracts?appId=${extraData.applicationId}`;
          break;
        case "contract":
            extraData.link = `${process.env.VUE_APP_FRONT_URL}/contracts?contractId=${extraData.contractId}`;
            extraData.linkclient = `${process.env.VUE_APP_FRONT_URL}/contracts?contractId=${extraData.contractId}`;
            extraData.linksales = `${process.env.VUE_APP_FRONT_URL}/createContracts?appId=${extraData.applicationId}`;
          break;
        case "case": 
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/cases?caseId=${extraData.caseId}`;
          extraData.linkclient = `${process.env.VUE_APP_FRONT_URL}/cases?caseId=${extraData.caseId}`;
          break;
        case "user": 
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/profile?userId=${extraData.userId}`;
          break;
        case "calendar": 
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/calendar?caseId=${extraData.caseId}`;
          break;
        case "auditCase": 
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/auditCases?caseId=${extraData.caseId}`;
          break;
        case "certificate":
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/certifications?certId=${extraData.certificationId}`;
          break;
        case "both": 
          extraData.link = `${process.env.VUE_APP_FRONT_URL}/cases?caseId=${extraData.caseId}`;
          extraData.linkaudit = `${process.env.VUE_APP_FRONT_URL}/auditCases?caseId=${extraData.caseId}`;
          extraData.linkinvoice = `${process.env.VUE_APP_FRONT_URL}/invoices?caseId=${extraData.caseId}`;
          extraData.linkcalendar = `${process.env.VUE_APP_FRONT_URL}/calendar?caseId=${extraData.caseId}`;
          extraData.linkauditsauditor = `${process.env.VUE_APP_FRONT_URL}/audits?caseId=${extraData.caseId}`;
          break;
      }
      if (extraData) {
        for (const el in extraData) {
          extras += `${el}=${extraData[el]};`;
        }
      }

      extras += `username=${username};`;
      app.config.globalProperties.$store.dispatch("action", {
        username,
        role,
        action,
        userid,
        extras,
      });
    }

    app.config.globalProperties.$action = action;
  },
};
