// import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";
import MainPage from "./MainPage.vue";
import Login from "./pages/Login.vue";
import Register from "./pages/Register.vue";
import Error from "./pages/Error.vue";
import Access from "./pages/Access.vue";
import NotFound from "./pages/NotFound.vue";
// import Information from "./components/Applications/CreateApplications/Information.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/access",
    name: "Access",
    component: Access,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/notFound",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/main",
    name: "Main",
    component: MainPage,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        exact: true,
        component: () => import("./pages/Dashboard.vue"),
        meta: {
          // requiresAuth: true
          breadcrumb: {
            label: "dashboard",
          },
        },
      },
      {
        path: "/roles",
        name: "roles",
        exact: true,
        component: () => import("./components/Roles/Roles.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "roles",
          },
        },
      },
      {
        path: "/certifications",
        name: "certifications",
        exact: true,
        component: () =>
          import("./components/Certifications/Certifications.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "certifications",
          },
        },
      },
      {
        path: "/users",
        name: "users",
        exact: true,
        component: () => import("./components/Users/Users.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "users",
          },
        },
      },
      {
        path: "/schemes",
        name: "schemes",
        exact: true,
        component: () => import("./components/Schemes/Schemes.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "schemes",
          },
        },
      },
      {
        path: "/calendar",
        name: "calendar",
        exact: true,
        component: () => import("./components/Calendar/Calendar.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "calendar",
          },
        },
      },
      {
        path: "/profile",
        name: "profile",
        exact: true,
        component: () => import("./components/Profile.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "profile",
          },
        },
      },
      {
        path: "/applications",
        name: "applications",
        exact: true,
        component: () => import("./components/Applications/Applications.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "applications",
          },
        },
      },
      {
        path: "/createApplication",
        name: "createApplication",
        exact: true,
        component: () =>
          import(
            "./components/Applications/CreateApplications/CreateApplication.vue"
          ),
        meta: {
          breadcrumb: {
            parent: ["dashboard", "applications"],
          },
        },
        children: [
          {
            path: "information",
            name: "companyInformation",
            exact: true,
            component: () =>
              import(
                "./components/Applications/CreateApplications/Information.vue"
              ),
            meta: {
              breadcrumb: {
                parent: ["dashboard", "applications"],
                label: "companyInformation",
              },
            },
          },
          {
            path: "details",
            name: "applicationDetails",
            exact: true,
            component: () =>
              import(
                "./components/Applications/CreateApplications/Details.vue"
              ),
            meta: {
              breadcrumb: {
                parent: ["dashboard", "applications"],
                label: "applicationDetails",
              },
            },
          },
          {
            path: "downloadAndSign",
            name: "applicationDownloadAndSign",
            exact: true,
            component: () =>
              import(
                "./components/Applications/CreateApplications/DownloadAndSign.vue"
              ),
            meta: {
              breadcrumb: {
                parent: ["dashboard", "applications"],
                label: "applicationDownloadAndSign",
              },
            },
          },
          {
            path: "uploadSigned",
            name: "applicationUploadSigned",
            exact: true,
            component: () =>
              import(
                "./components/Applications/CreateApplications/UploadSigned.vue"
              ),
            meta: {
              breadcrumb: {
                parent: ["dashboard", "applications"],
                label: "applicationUploadSigned",
              },
            },
          },
        ],
      },
      {
        path: "/contracts",
        name: "contracts",
        exact: true,
        component: () => import("./components/Contracts/Contracts.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "contracts",
          },
        },
      },
      {
        path: "/createContracts",
        name: "createContracts",
        exact: true,
        component: () => import("./components/Contracts/CreateContracts.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "createContracts",
          },
        },
      },
      {
        path: "/clients",
        name: "clients",
        exact: true,
        component: () => import("./components/Clients/Clients.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "clients",
          },
        },
      },
      {
        path: "/clientInformation",
        name: "clientInformation",
        exact: true,
        component: () => import("./components/Clients/ClientInformation.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "clientInformation",
          },
        },
      },
      {
        path: "/cases",
        name: "cases",
        exact: true,
        component: () => import("./components/Cases/Cases.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "cases",
          },
        },
      },
      {
        path: "/auditCases",
        name: "auditCases",
        exact: true,
        component: () => import("./components/AuditCases.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "auditCases",
          },
        },
      },
      {
        path: "/files",
        name: "files",
        exact: true,
        component: () => import("./components/Files/Files.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "files",
          },
        },
      },
      {
        path: "/invoices",
        name: "invoices",
        exact: true,
        component: () => import("./components/Invoices.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "invoices",
          },
        },
      },
      {
        path: "/audits",
        name: "audits",
        exact: true,
        component: () => import("./components/Audits.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "audits",
          },
        },
      },
      {
        path: "/settings",
        name: "settings",
        exact: true,
        component: () => import("./components/Settings/Settings.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "settings",
          },
        },
        redirect: () => {
          return { name: "templates" };
        },
        children: [
          {
            path: "templates",
            name: "templates",
            exact: true,
            component: () => import("./components/Settings/Templates.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "templates",
              },
            },
          },
          {
            path: "countries",
            name: "countries",
            exact: true,
            component: () =>
              import("./components/Settings/Countries/Countries.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "countries",
              },
            },
          },
          {
            path: "cities",
            name: "cities",
            exact: true,
            component: () => import("./components/Settings/Cities/Cities.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "cities",
              },
            },
          },
          {
            path: "doy",
            name: "doy",
            exact: true,
            component: () => import("./components/Settings/Doy/Doy.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "doy",
              },
            },
          },
          {
            path: "companyType",
            name: "companyType",
            exact: true,
            component: () =>
              import("./components/Settings/CompanyType/CompanyType.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "companyType",
              },
            },
          },
          {
            path: "notifications",
            name: "notifications",
            exact: true,
            component: () =>
              import("./components/Settings/Notifications/Notifications.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "notifications",
              },
            },
          },
          {
            path: "actions",
            name: "actions",
            exact: true,
            component: () =>
              import("./components/Settings/Actions/Actions.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "actions",
              },
            },
          },
          {
            path: "variables",
            name: "variables",
            exact: true,
            component: () => import("./components/Settings/Variables.vue"),
            meta: {
              breadcrumb: {
                parent: ["dashboard"],
                label: "variables",
              },
            },
          },
        ],
      },
      {
        path: "/complaints",
        name: "complaints",
        exact: true,
        component: () => import("./components/Complaints/Complaints.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "complaints",
          },
        },
      },
      {
        path: "/renewals",
        name: "renewals",
        exact: true,
        component: () => import("./components/Renewals/Renewals.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "renewals",
          },
        },
      },
      {
        path: "/ratings",
        name: "ratings",
        exact: true,
        component: () => import("./components/Ratings.vue"),
        meta: {
          breadcrumb: {
            parent: ["dashboard"],
            label: "ratings",
          },
        },
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("./pages/Logout.vue"),
  },
  {
    path: "/",
    name: "Home page",
    exact: true,
    beforeEnter: (to, from, next) => {
      next("dashboard");
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  // Cookies.set("SESSION", router.app.config.globalProperties.$keycloak.token);

  //Execute keycloak login
  if (to.name == "Login") {
    router.app.config.globalProperties.$store.dispatch("login");
  }

  //Execute keycloak logout
  // if (to.name == "Logout") {
  //   // router.app.config.globalProperties.$action('logout');
  //   router.app.config.globalProperties.$keycloak.logout({
  //     redirectUri: process.env.VUE_APP_LOGOUT_URL,
  //   });
  // }

  //Execute keycloak register
  if (to.name == "Register") {
    router.app.config.globalProperties.$keycloak.register({
      redirectUri: process.env.VUE_APP_FRONT_URL,
    });
  }

  //Change the title of the webpage depending on the route name
  document.title =
    router.app.config.globalProperties.$t("global.chinaBeeReady") +
    " | " +
    to.name.charAt(0).toUpperCase() +
    to.name.slice(1).toLowerCase();

  if (
    router.app.config.globalProperties.$keycloak.authenticated &&
    Object.keys(router.app.config.globalProperties.$store.getters.userInfo)
      .length == 0
  ) {
    await router.app.config.globalProperties.$store.dispatch("userInfo");
  }

  if (to.name == "calendar") {
    // console.log(router.app.config.globalProperties.$store.getters.checkRole(["auditor"]));
    if (
      Object.keys(router.app.config.globalProperties.$store.getters.userInfo)
        .length > 0 &&
      router.app.config.globalProperties.$store.getters.checkRole(["auditor"])
    ) {
      to.meta.breadcrumb.label = "assignedAudits";
    }
  }
  const pagesPermissions =
    router.app.config.globalProperties.$store.state.pagesPermissions;

  if (!to.matched.some((record) => "requiresAuth" in record.meta)) {
    if (router.app.config.globalProperties.$keycloak.authenticated) {
      if (pagesPermissions[to.name]) {
        if (
          router.app.config.globalProperties.$store.getters.checkRole(
            pagesPermissions[to.name]
          )
        ) {
          next();
        } else {
          next(from);
        }
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
