<template>
  <Toast>
    <template #message="slotProps">
      <span :class="`p-toast-message-icon pi ${slotProps.message.icon}`">
      </span>
      <div class="p-toast-message-text">
        <span class="p-toast-summary">
          {{ slotProps.message.summary }}
        </span>
        <div class="p-toast-detail" v-html="slotProps.message.detail"></div>
      </div>
    </template>
  </Toast>
  <div v-if="!finished" class="spinner">
    <ProgressSpinner />
  </div>
  <div
    :class="containerClass"
    :data-theme="colorScheme"
    @click="onDocumentClick($event)"
    v-else
  >
    <div class="layout-content-wrapper">
      <AppTopBar
        :topbarNotificationMenuActive="topbarNotificationMenuActive"
        :topbarUserMenuActive="topbarUserMenuActive"
        :layoutMode="layoutMode"
        :menu="menu"
        :menuActive="menuActive"
        :staticMenuMobileActive="staticMenuMobileActive"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
        @menu-button-click="onMenuButtonClick"
        @search-click="toggleSearch"
        @topbar-notification="onTopbarNotificationMenuButtonClick"
        @topbar-user-menu="onTopbarUserMenuButtonClick"
        @right-menu-click="onRightMenuButtonClick"
        @right-menubutton-click="onRightMenuButtonClick"
        v-if="finished"
      ></AppTopBar>

      <div class="layout-content">
        <div
          class="layout-breadcrumb viewname"
          style="text-transform: uppercase"
        >
          <template v-if="$route.meta.breadcrumb">
            <span>{{ $route.meta.breadcrumb.label }}</span>
          </template>
        </div>

        <router-view v-if="finished" />
      </div>
      <AppFooter />
    </div>

    <AppRightMenu
      :rightMenuActive="rightMenuActive"
      @right-menu-click="onRightMenuClick"
    ></AppRightMenu>

    <!-- <AppConfig
      v-model:configActive="configActive"
      v-model:layoutMode="layoutMode"
      v-model:menuTheme="menuTheme"
      v-model:colorScheme="colorScheme"
      @config-click="onConfigClick"
      @config-button-click="onConfigButtonClick"
    ></AppConfig> -->

    <AppSearch
      :searchActive="searchActive"
      @search-click="onSearchClick"
      @search-hide="onSearchHide"
    />
  </div>
</template>

<script>
import EventBus from "./event-bus";
import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
// import AppConfig from "./AppConfig";
import AppSearch from "./AppSearch";
import AppRightMenu from "./AppRightMenu";
import { mapGetters, mapState } from "vuex";
import Pusher from "pusher-js/with-encryption";

export default {
  data() {
    return {
      menuActive: false,
      layoutMode: "static",
      colorScheme: "light",
      menuTheme: "layout-sidebar-darkgray",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      userMenuClick: false,
      topbarUserMenuActive: false,
      notificationMenuClick: false,
      topbarNotificationMenuActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      configActive: false,
      configClick: false,
      finished: false,
    };
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("userInfo"),
      this.$store.dispatch(
        "clientInfo",
        this.checkRole(["client"]) ? { checkapp: true } : {}
      ),
    ]);
    this.$store.dispatch("updateToken");
    this.$i18n.locale = this.userInfo.language ? this.userInfo.language : "el";
    
    //Litsen for notifications
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: process.env.VUE_APP_BACK_URL + "pusher/user-auth",
        headers: {
          Authorization: "Bearer " + this.$keycloak.token,
        },
      },
    });

    const channel = pusher.subscribe("private-encrypted-notifications");
    channel.bind(
      `notification-${this.userInfo.sub}`,
      (data) => {
        console.log(data);
        let severity = "";
        let icon = "";
        switch (data.severity) {
          case "1":
            severity = "success";
            icon = "pi-check";
            break;
          case "2":
            severity = "info";
            icon = "pi-info-circle";
            break;
          case "3":
            severity = "warn";
            icon = "pi-exclamation-triangle";
            break;
          case "4":
            severity = "error";
            icon = "pi-times-circle";
            break;
        }
        this.$toast.add({
          severity,
          summary: data.title,
          detail: data.message,
          life: 3000,
          icon,
        });
        this.$store.commit("addNotification", data);
      },
      channel.unbind()
    );

    this.finished = true;
  },
  computed: {
    ...mapGetters(["checkPermission", "checkRole", "userInfo"]),
    ...mapState(["pagesPermissions"]),
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-slim": this.layoutMode === "slim",
          "layout-horizontal": this.layoutMode === "horizontal",
          "layout-sidebar-dim": this.colorScheme === "dim",
          "layout-sidebar-dark": this.colorScheme === "dark",
          "layout-overlay-active": this.overlayMenuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.layoutMode === "static",
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": !this.$primevue.config.ripple,
        },
        this.colorScheme === "light" ? this.menuTheme : "",
      ];
    },
    menu() {
      return [
        {
          label: this.$t("routes.navigations"),
          items: [
            {
              icon: "mdi mdi-dark mdi-view-grid",
              label: this.$t("routes.dashboard"),
              to: "/dashboard",
            },
            ...(this.checkRole(this.pagesPermissions.createContracts)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-file-sign",
                    label: this.$t("routes.createContracts"),
                    to: "/createContracts",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.renewals)
              ? [
                  {
                    icon: "mdi mdi-progress-star",
                    label: this.$t("routes.renewals"),
                    to: "/renewals",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.roles)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-shield-account",
                    label: this.$t("routes.roles"),
                    to: "/roles",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.users)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-account-circle",
                    label: this.$t("routes.users"),
                    to: "/users",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.schemes)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-format-list-numbered",
                    label: this.$t("routes.schemes"),
                    to: "/schemes",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.applications)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-clipboard-text-multiple",
                    label: this.$t("routes.applications"),
                    to: "/applications",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.contracts)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-file-sign",
                    label: this.$t("routes.contracts"),
                    to: "/contracts",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.calendar)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-calendar-month",
                    label: this.checkRole(["auditor"])
                      ? this.$t("routes.assignedAudits")
                      : this.$t("routes.calendar"),
                    to: "/calendar",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.cases)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-clipboard-list",
                    label: this.$t("routes.cases"),
                    to: "/cases",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.invoices)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-receipt",
                    label: this.$t("routes.invoices"),
                    to: "/invoices",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.certifications)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-certificate",
                    label: this.$t("routes.certifications"),
                    to: "/certifications",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.files)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-folder-table",
                    label: this.$t("routes.files"),
                    to: "/files",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.auditCases)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-clipboard-list",
                    label: this.$t("routes.auditCases"),
                    to: "/auditCases",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.audits)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-receipt",
                    label: this.$t("routes.audits"),
                    to: "/audits",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.clients)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-account-group",
                    label: this.$t("routes.clients"),
                    to: "/clients",
                  },
                ]
              : []),

            {
              icon: "mdi mdi-dark mdi-account-child-circle",
              label: this.$t("routes.profile"),
              to: "/profile",
            },
            ...(this.checkRole(this.pagesPermissions.settings)
              ? [
                  {
                    icon: "mdi mdi-dark mdi-cog",
                    label: this.$t("routes.settings"),
                    to: "/settings",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.complaints)
              ? [
                  {
                    icon: "mdi mdi-google-hangouts",
                    label: this.$t("routes.complaints"),
                    to: "/complaints",
                  },
                ]
              : []),
            ...(this.checkRole(this.pagesPermissions.ratings)
              ? [
                  {
                    icon: "mdi mdi-chart-bar",
                    label: this.$t("routes.ratings"),
                    to: "/ratings",
                  },
                ]
              : []),
          ],
        },
        { separator: true },
        {
          items: [
            {
              icon: "mdi mdi-dark mdi-account-arrow-right",
              label: this.$t("routes.logout"),
              to: "/logout",
            },
          ],
        },
        // {
        //   label: "UI Kit",
        //   icon: "pi pi-fw pi-star-fill",
        //   items: [
        //     { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
        //     {
        //       label: "Form Layout",
        //       icon: "pi pi-fw pi-id-card",
        //       to: "/formlayout",
        //     },
        //     { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //     {
        //       label: "Float Label",
        //       icon: "pi pi-fw pi-bookmark",
        //       to: "/floatlabel",
        //     },
        //     {
        //       label: "Invalid State",
        //       icon: "pi pi-fw pi-exclamation-circle",
        //       to: "/invalidstate",
        //     },
        //     {
        //       label: "Button",
        //       icon: "pi pi-fw pi-mobile",
        //       to: "/button",
        //       class: "rotated-icon",
        //     },
        //     { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //     { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //     { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //     { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //     { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //     { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
        //     { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //     { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //     { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //     { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //     { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //   ],
        // },
        // { separator: true },
        // {
        //   label: "Utilities",
        //   icon: "pi pi-fw pi-compass",
        //   items: [
        //     { label: "Display", icon: "pi pi-fw pi-desktop", to: "/display" },
        //     {
        //       label: "Elevation",
        //       icon: "pi pi-fw pi-external-link",
        //       to: "/elevation",
        //     },
        //     {
        //       label: "Flexbox",
        //       icon: "pi pi-fw pi-directions",
        //       to: "/flexbox",
        //     },
        //     { label: "Icons", icon: "pi pi-fw pi-prime", to: "/icons" },
        //     { label: "Text", icon: "pi pi-fw pi-pencil", to: "/text" },
        //     { label: "Widgets", icon: "pi pi-fw pi-star", to: "/widgets" },
        //     { label: "Grid System", icon: "pi pi-fw pi-th-large", to: "/grid" },
        //     {
        //       label: "Spacing",
        //       icon: "pi pi-fw pi-arrow-right",
        //       to: "/spacing",
        //     },
        //     {
        //       label: "Typography",
        //       icon: "pi pi-fw pi-align-center",
        //       to: "/typography",
        //     },
        //   ],
        // },
        // { separator: true },
        // {
        //   label: "UI Blocks",
        //   icon: "pi pi-building",
        //   items: [
        //     {
        //       label: "Free Blocks",
        //       icon: "pi pi-fw pi-eye",
        //       to: "/blocks",
        //       badge: "NEW",
        //     },
        //     {
        //       label: "All Blocks",
        //       icon: "pi pi-fw pi-globe",
        //       url: "https://www.primefaces.org/primeblocks-vue",
        //     },
        //   ],
        // },
        // { separator: true },
        // {
        //   label: "Pages",
        //   icon: "pi pi-fw pi-briefcase",
        //   items: [
        //     { label: "Crud", icon: "pi pi-fw pi-pencil", to: "/crud" },
        //     {
        //       label: "Calendar",
        //       icon: "pi pi-fw pi-calendar-plus",
        //       to: "/calendar",
        //     },
        //     {
        //       label: "Timeline",
        //       icon: "pi pi-fw pi-calendar",
        //       to: "/timeline",
        //     },
        //     {
        //       label: "Landing",
        //       icon: "pi pi-fw pi-user-plus",
        //       url: "pages/landing.html",
        //       target: "_blank",
        //     },
        //     { label: "Login", icon: "pi pi-fw pi-sign-in", to: "/login" },
        //     { label: "Invoice", icon: "pi pi-fw pi-dollar", to: "/invoice" },
        //     { label: "Help", icon: "pi pi-fw pi-question-circle", to: "/help" },
        //     { label: "Error", icon: "pi pi-fw pi-times-circle", to: "/error" },
        //     {
        //       label: "Not Found",
        //       icon: "pi pi-fw pi-exclamation-circle",
        //       to: "/notfound",
        //     },
        //     { label: "Access Denied", icon: "pi pi-fw pi-lock", to: "/access" },
        //     { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //   ],
        // },
        // { separator: true },
        // {
        //   label: "Hierarchy",
        //   icon: "pi pi-fw pi-align-left",
        //   items: [
        //     {
        //       label: "Submenu 1",
        //       icon: "pi pi-fw pi-align-left",
        //       items: [
        //         {
        //           label: "Submenu 1.1",
        //           icon: "pi pi-fw pi-align-left",
        //           items: [
        //             { label: "Submenu 1.1.1", icon: "pi pi-fw pi-align-left" },
        //             { label: "Submenu 1.1.2", icon: "pi pi-fw pi-align-left" },
        //             { label: "Submenu 1.1.3", icon: "pi pi-fw pi-align-left" },
        //           ],
        //         },
        //         {
        //           label: "Submenu 1.2",
        //           icon: "pi pi-fw pi-align-left",
        //           items: [
        //             { label: "Submenu 1.2.1", icon: "pi pi-fw pi-align-left" },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       label: "Submenu 2",
        //       icon: "pi pi-fw pi-align-left",
        //       items: [
        //         {
        //           label: "Submenu 2.1",
        //           icon: "pi pi-fw pi-align-left",
        //           items: [
        //             { label: "Submenu 2.1.1", icon: "pi pi-fw pi-align-left" },
        //             { label: "Submenu 2.1.2", icon: "pi pi-fw pi-align-left" },
        //           ],
        //         },
        //         {
        //           label: "Submenu 2.2",
        //           icon: "pi pi-fw pi-align-left",
        //           items: [
        //             { label: "Submenu 2.2.1", icon: "pi pi-fw pi-align-left" },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // { separator: true },
        // {
        //   label: "Start",
        //   icon: "pi pi-fw pi-download",
        //   items: [
        //     {
        //       label: "Buy Now",
        //       icon: "pi pi-fw pi-shopping-cart",
        //       command: () =>
        //         window.open("https://www.primefaces.org/store", "_blank"),
        //     },
        //     {
        //       label: "Documentation",
        //       icon: "pi pi-fw pi-info-circle",
        //       to: "/documentation",
        //     },
        //   ],
        // },
      ];
    },
  },
  components: {
    AppTopBar,
    AppFooter,
    // AppConfig,
    AppSearch,
    AppRightMenu,
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (
        !this.notificationMenuClick &&
        document.querySelector(".notifications-menu")?.contains(event.target) ==
          false
      ) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim() || this.isHorizontal()) {
          EventBus.emit("reset-active-index");
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit("reset-active-index");
        this.hideOverlayMenu();
      }
      if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick() {
      this.searchClick = true;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onRightMenuButtonClick(event) {
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isSlim() {
      return this.layoutMode === "slim";
    },
    isHorizontal() {
      return this.layoutMode === "horizontal";
    },
    isOverlay() {
      return this.layoutMode === "overlay";
    },
    isDesktop() {
      return window.innerWidth > 1091;
    },
  },
};
</script>

<style lang="scss">
#footer {
  width: 100% !important;
}

.mdi-96px.mdi-set,
.mdi-96px.mdi:before {
  font-size: 96px;
}

.mdi-orange:before {
  color: #f8991d;
}
</style>

<style lang="scss">
.spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
