export default {
        install(app) {
                /**
                 * 
                 * @typedef {"success" | "error" | "info"} severity
                 * 
                 * @param {string} summary 
                 * @param {string} detail 
                 * @param severity {severity}
                 * @param {string} life 
                 * @returns 
                 */
                function notification(summary, detail,severity="success", life=3000) {
                        // console.log(app.config.globalProperties);
                        return app.config.globalProperties.$toast.add({
                                severity,
                                summary,
                                detail,
                                life,
                        });
                }

                app.config.globalProperties.$notification = notification;

        }
}