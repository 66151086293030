<template>
<div class="layout-footer justify-content-evenly" style="border-top: 1px solid #dee2e6;">
  <p class="font-italic text-xs md:text-sm text-center py-2">
  «{{$t('global.footer')}}»
  </p>
</div>
  <div class="layout-footer flex-column md:flex-row">
    <p>
      v{{version}}
    </p>
    <div>
      <img style="height: auto; padding: 5px 0; max-width: 250px;;" src="/layout/images/espa.jpg" alt="">
    </div>
    <p>
      {{ year }} Copyright &copy; <a href="https://www.toorbee.com">Toorbee</a>
    </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["version"])
  }
};
</script>

<style lang="scss" scoped>
.layout-footer {
  justify-content: space-between;
}

p {
  margin:0;
}
</style>
