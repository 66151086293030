<template>
  <div>
    <div class="split left"></div>

    <div class="split right">
      <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="centered">
          <img class="logo" src="layout/images/logo.png" alt="Toorbee logo" />

          <h4 id="title">Login into your company</h4>

          <div class="grid flex-column">
            <div class="field p-fluid col">
              <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email ID</label>
              <InputText id="email" type="email" :class="{'p-invalid':v$.email.$invalid && submitted}" v-model="v$.email.$model" aria-describedby="email-error"/>
              <span v-if="v$.email.$error && submitted">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                </span>
                <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
            </div>
            <div class="field p-fluid col">
              <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password</label>
              <InputText id="password" type="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" />
              <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
            </div>
            <div class="alignItems">
              <div class="col-6">
                <div class="field-checkbox">
                  <Checkbox
                    id="keepLoggedIn"
                    v-model="keepLoggedIn"
                    :binary="true"
                  />
                  <label for="keepLoggedIn">Keep me signed in</label>
                </div>
              </div>
              <div class="col-6" id="forgotPass">
                <div>
                  <router-link to="/forgotPassword"
                    >Forgot password?</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col" id="buttonBox">
              <Button id="button" label="Login" type="submit" class="p-button-rounded" @click="login"/>
            </div>
            <div class="col text">
              <p>
                Dont have an account?
                <router-link style="text-decoration: underline" to="/register"
                  >Register now</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </form>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { email, required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import Footer from "../AppFooter.vue";

export default {
  name: "Login",
  components: {
    Footer,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
        password: "",
        email: "",
        keepLoggedIn: false,
        submitted: false,
    };
  },
  validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required
            }
        }
    },
  methods: {
          handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            this.$router.push('/main/dashboard');
          },
          login() {
            // this.$store.dispatch("login");
          }
  }
};
</script>

<style lang="scss" scoped>
.centered {
  top: 35%;
}

.alignItems {
  display: flex;
  justify-content: space-between;
}

#forgotPass {
  text-align: end;
}

.text {
        margin-top: 5%;
}

.col {
        padding:0;
}
</style>
