<template>
  <div>
    <div class="layout-topbar">
      <div class="topbar-left">
        <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
          <i class="pi pi-chevron-left"></i>
        </a>

        <router-link to="/" class="horizontal-logo">
          <img
            id="logo-horizontal"
            class="horizontal-logo"
            src="layout/images/logo-white.svg"
            alt="diamond-layout"
          />
        </router-link>

        <span class="topbar-separator"></span>

        <div class="layout-breadcrumb viewname capitalize text-center md:text-left">
          <template v-if="$route.meta.breadcrumb.label">
            <span>{{ $t(`routes.${$route.meta.breadcrumb.label}`) }}</span>
          </template>
        </div>

        <img
          id="logo-mobile"
          class="mobile-logo bg-white"
          src="/logo.svg"
          alt="diamond-layout"
        />
      </div>

      <AppMenu
        :model="menu"
        :layoutMode="layoutMode"
        :active="menuActive"
        :mobileMenuActive="staticMenuMobileActive"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      ></AppMenu>

      <div class="layout-mask modal-in"></div>

      <div v-if="screenWidth > 1091" class="topbar-right">
        <ul class="topbar-menu">
          <!-- <li>
          <Dropdown
            v-model="$i18n.locale"
            :options="languages"
            optionLabel="name"
            optionValue="code"
          class="languageSelector"
          />
        </li> -->
          <!-- <li class="search-item">
          <a tabindex="0" @click="onSearchClick">
            <i class="pi pi-search"></i>
          </a>
        </li> -->
          <li
            class="notifications-item"
            :class="{ 'active-menuitem ': topbarNotificationMenuActive }"
            v-if="notifications.length > 0"
          >
            <a
              href="#"
              tabindex="0"
              @click="onTopbarNotificationMenuButtonClick"
            >
              <i class="pi pi-bell" style="color: #ffffff !important"></i>
              <span class="topbar-badge">{{
                notificationsLength > 99 ? "99+" : notificationsLength
              }}</span>
            </a>
            <ul
              class="notifications-menu fade-in-up py-0"
              style="max-height: 40vh; overflow-y: scroll; width: 50vh"
            >
              <div
                role="menuitem"
                v-for="(notification, index) in notifications"
                :key="notification.id"
              >
                <!-- <Message
                  v-if="notification.readByAdmin == false"
                  :severity="severity(notification)"
                  @close="readNotification(notification, index)"
                  ><div v-html="notification.message"></div
                ></Message> -->
                <NotificationMessage
                  :notification="notification"
                  :index="index"
                ></NotificationMessage>
              </div>
              <!-- <li role="menuitem">
              <a href="#" tabindex="0">
                <i class="pi pi-shopping-cart"></i>
                <div class="notification-item">
                  <div class="notification-summary">New Order</div>
                  <div class="notification-detail">
                    You have <strong>3</strong> new orders.
                  </div>
                </div>
              </a>
            </li>
            <li role="menuitem">
              <a href="#" tabindex="0">
                <i class="pi pi-check-square"></i>
                <div class="notification-item">
                  <div class="notification-summary">Withdrawn Completed</div>
                  <div class="notification-detail">
                    Funds are on their way.
                  </div>
                </div>
              </a>
            </li>
            <li role="menuitem">
              <a href="#" tabindex="0">
                <i class="pi pi-chart-line"></i>
                <div class="notification-item">
                  <div class="notification-summary">Monthly Reports</div>
                  <div class="notification-detail">
                    New reports are ready.
                  </div>
                </div>
              </a>
            </li>
            <li role="menuitem">
              <a href="#" tabindex="0">
                <i class="pi pi-comments"></i>
                <div class="notification-item">
                  <div class="notification-summary">Comments</div>
                  <div class="notification-detail">
                    <strong>2</strong> new comments.
                  </div>
                </div>
              </a>
            </li>
            <li role="menuitem">
              <a href="#" tabindex="0">
                <i class="pi pi-exclamation-circle"></i>
                <div class="notification-item">
                  <div class="notification-summary">Chargeback Request</div>
                  <div class="notification-detail">
                    <strong>1</strong> to review.
                  </div>
                </div>
              </a>
            </li> -->
            </ul>
          </li>

          <li
            class="profile-item"
            :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }"
            @click="toggleMenu"
            aria-haspopup="true"
            aria-controls="menu"
          >
            <a>
              <!-- <img
                src="/demo/images/avatar/profile.jpg"
                alt="diamond-layout"
                class="profile-image"
              /> -->
              <Avatar
                :label="
                  userInfo.given_name[0].toUpperCase() +
                  userInfo.family_name[0].toUpperCase()
                "
                class="mr-3"
                style="color: #0095da"
                shape="circle"
              />
              <div class="grid flex-column grid-nogutter">
                <div class="col text-left">
                  <p class="profile-name text-white">{{ userInfo.name }}</p>
                </div>
                <div class="col text-left text-white text-xs">
                  <p>{{ getUserRoleTranslated }}</p>
                </div>
              </div>
            </a>
            <ul class="profile-menu fade-in-up">
              <li>
                <a href="#">
                  <i class="pi pi-user"></i>
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="pi pi-cog"></i>
                  <span>Settings</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="pi pi-calendar"></i>
                  <span>Calendar</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="pi pi-inbox"></i>
                  <span>Inbox</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="pi pi-power-off"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </li>

          <!-- <li class="right-sidebar-item">
          <a href="#" tabindex="0" @click="onRightMenuClick">
            <i class="pi pi-align-right"></i>
          </a>
        </li> -->
        </ul>
        <Menu id="menu" ref="menu" :model="menuItems" :popup="true" />
      </div>
    </div>
    <div class="layout-topbar px-4 breadcrumb bg-black-alpha-90 flex-nowrap">
      <Button
        v-if="screenWidth < 1091"
        @click="backRoute"
        class="p-button-text outline-none"
        style="box-shadow: none"
        icon="mdi mdi-chevron-left mdi-36px"
      >
      </Button>
      <ul
        class="list-none p-0 m-0 flex align-items-center font-medium text-center"
      >
        <template
          v-for="(route, index) of $route.meta.breadcrumb.parent"
          :key="index"
        >
          <li>
            <router-link :to="`/${route}`">
              <a class="text-0 no-underline line-height-3 cursor-pointer">{{
                $t(`routes.${route}`)
              }}</a>
            </router-link>
          </li>
          <li class="px-2">
            <i class="pi pi-angle-right text-500 line-height-3"></i>
          </li>
        </template>
        <li>
          <a
            v-if="$route.meta.breadcrumb.label"
            class="text-0 no-underline line-height-3 cursor-pointer"
            :href="$route.path"
            >{{ $t(`routes.${$route.meta.breadcrumb.label}`) }}</a
          >
        </li>
      </ul>
      <Button
        v-if="screenWidth < 1091"
        @click="frontRoute"
        class="p-button-text outline-none"
        style="box-shadow: none"
        icon="mdi mdi-chevron-right mdi-36px"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppMenu from "./AppMenu";
import NotificationMessage from "./components/Dashboard/NotificationMessage";

export default {
  name: "AppTopbar",
  emits: [
    "menu-click",
    "menuitem-click",
    "root-menuitem-click",
    "menu-button-click",
    "search-click",
    "topbar-notification",
    "topbar-user-menu",
    "right-menubutton-click",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    layoutMode: String,
    menu: Array,
    menuActive: Boolean,
    staticMenuMobileActive: Boolean,
  },
  data() {
    return {
      items: [],
      screenWidth: window.innerWidth,
      notificationsLength: 0,
      menuItems: [
        {
          label: this.$t("routes.profile"),
          to: "/profile",
        },
        {
          label: this.$t("routes.logout"),
          to: "/logout",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "getUserRoleTranslated", "notifications"]),
  },
  components: {
    AppMenu,
    NotificationMessage,
  },
  watch: {
    notifications: {
      handler() {
        this.notificationsLength = this.notifications.filter(
          (el) => el.readByAdmin == false
        ).length;
      },
      deep: true
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    this.$store.dispatch("notifications", this.userInfo.sub);
  },
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  methods: {
    closeNotifications() {
      console.log("close notifications");
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    severity(val) {
      let status = "";
      switch (val.severity) {
        case "1":
          status = "success";
          break;
        case "2":
          status = "info";
          break;
        case "3":
          status = "warn";
          break;
        case "4":
          status = "error";
          break;
      }
      return status;
    },
    readNotification(notification, index) {
      this.$store.commit("removeNotification", index);
      this.$store.dispatch("readNotification", {
        id: notification.id,
        read: true,
      });
    },
    backRoute() {
      this.$router.back();
    },
    frontRoute() {
      this.$router.forward();
    },
    onMenuClick(event) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
    onMenuButtonClick(event) {
      this.$emit("menu-button-click", event);
    },
    onSearchClick(event) {
      this.$emit("search-click", event);
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event) {
      this.$emit("topbar-user-menu", event);
    },
    onRightMenuClick(event) {
      this.$emit("right-menubutton-click", event);
    },
    isMobile() {
      return window.innerWidth <= 1091;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  top: 4rem;
  z-index: 2;
}
p.profile-name:hover {
  color: #f8991d !important;
}
</style>
