import {createI18n} from 'vue-i18n';
import en from './languages/en.json'
import el from './languages/el.json'

const messages= {
        en,
        el
}

const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale: 'el',
        fallbackLocale: 'en',
        messages,
        availableLocales: ['en', 'el']
})

export default i18n;