<template>
  <router-view />
</template>

<script>
// import SockJS from "sockjs-client";
// import Stomp from "webstomp-client";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      stompClient: null,
    };
  },
  computed: {
    ...mapGetters(["getConnected"]),
  },
  // methods: {
  //   connect() {
  //     const socket = new SockJS("https://cbrbackend.dev.toorbee.com/socket");
  //     this.stompClient = Stomp.over(socket);
  //     this.stompClient.connect({}, function (frame) {
  //       this.$store.commit.setConnected(true);
  //       console.log("Connected: " + frame);
  //       this.sendMessage();
  //       this.stompClient.subscribe(
  //         "https://cbrbackend.dev.toorbee.com/topic/notifications",
  //         function (messageOutput) {
  //           console.log(JSON.parse(messageOutput));
  //         }
  //       );
  //     });
  //   },
  //   sendMessage() {
  //     this.stompClient.send(
  //       "https://cbrbackend.dev.toorbee.com/socket/app",
  //       {},
  //       JSON.stringify({ testData: "test message" })
  //     );
  //   },
  // },
  async mounted() {
    // await this.connect();
    // console.log(this.getConnected);
    // if(this.getConnected) {
    //   this.sendMessage();
    // }
    window.addEventListener("resize", () => {
      this.$store.commit("screenWidth", window.innerWidth);
    });
  },
};
</script>

<style lang="scss">
@import "App.scss";

.p-paginator-current {
  margin-left: auto !important;
}

.p-datatable-header {
  padding: 1rem;
  text-align: right;
  font-size: 1.5rem;
}

.p-toast {
  z-index: 100;
}
</style>
